<template>
  <div
    class="text-static-default-mid absolute inset-0 flex grow flex-col items-center justify-center gap-16 p-32"
    data-qa="quiz-loading"
  >
    <div class="heading-2 text-center">
      {{ loadingMessage }}
    </div>
    <RevSpinner />
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevSpinner } from '@ds/components/Spinner'

import translations from './Quiz.translations'

const $t = useI18n()

const loadingMessage = $t(translations.loadingMessage)
</script>
