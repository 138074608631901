<template>
  <div v-if="shouldDisplayTags">
    <h3 class="heading-3" data-selector="tags-pp-title">
      {{ i18n(translations.title) }}
    </h3>
    <div class="-mb-8 mt-20 flex overflow-x-auto pb-8 md:mt-32 md:flex-wrap">
      <RevButton
        v-for="tag in tags"
        :key="tag.title"
        class="mb-8 mr-8"
        data-selector="product-tag"
        size="small"
        :to="tag.link"
        variant="primary"
        @click="trackPopularSearchClick(tag)"
      >
        {{ tag.title }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { GetSeoTagsResponse } from '@backmarket/http-api/src/api-specs-acquisition/link-booster/tags'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'

import translations from './PopularSearches.translations'

const i18n = useI18n()
const { trackClick } = useTracking()

const props = defineProps<{
  tags: GetSeoTagsResponse
}>()

const shouldDisplayTags = computed(() => {
  return props.tags.length > 0
})

function trackPopularSearchClick(tag: GetSeoTagsResponse[number]) {
  trackClick({ zone: 'tags', name: `product-tag-${tag.slug}` })
}
</script>
