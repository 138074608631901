import { useRuntimeConfig } from '#imports'

import * as amplitude from '@amplitude/analytics-browser'

import type { UserProperties } from './types'

// BM is under the "growth plan" of Amplitude
// Documentation: https://amplitude.github.io/Amplitude-JavaScript/

const isUnitTest = process.env.VITEST === 'true'
const isE2ETestingEnvironment =
  window?.navigator?.userAgent === 'MrFatUserAgent'
const isTestingEnvironment = isUnitTest || isE2ETestingEnvironment

export const sendEvent = (eventName: string, payload: object) => {
  if (isTestingEnvironment) return

  amplitude.logEvent(eventName, payload)
}

export function setUserProperties(userProperties: UserProperties) {
  if (isTestingEnvironment) return

  const identify = new amplitude.Identify()

  Object.keys(userProperties).forEach((propertyName) => {
    identify.set(propertyName, userProperties[propertyName])
  })

  amplitude.identify(identify)
}

export const setUserId = (userId: string) => {
  if (isTestingEnvironment) return

  amplitude.setUserId(userId)
}

export const getDeviceId = () => {
  if (isTestingEnvironment) return ''

  return amplitude.getDeviceId()
}

export type InitAmplitudeParameters = {
  apiKey: string
  version: string
}

export function init() {
  const {
    public: { tracking: config },
  } = useRuntimeConfig()

  amplitude.init(config.amplitude.apiKey, {
    appVersion: config.version,
    flushIntervalMillis: 5000,
    logLevel: amplitude.Types.LogLevel.None,

    autocapture: {
      attribution: true,
      pageViews: false,
      sessions: true,
      formInteractions: false,
      fileDownloads: false,
      elementInteractions: false,
    },

    // serverZone is fixed to EU in order to keep the data out of the US.
    serverZone: 'EU',
  })

  // this function is invoked when page is unloaded
  // switches to beacon and sends all unsent events immediately
  // see https://www.docs.developers.amplitude.com/data/sdks/typescript-browser/migration/?h=onexitpage#flush-or-onexitpage
  window.addEventListener('pagehide', () => {
    // Set https transport to use sendBeacon API
    amplitude.setTransport('beacon')
    // Send all pending events to server
    amplitude.flush()
  })
}

export const setOptIn = (optIn: boolean) => {
  if (isTestingEnvironment) return

  if (optIn) init()

  amplitude.setOptOut(!optIn)
}
