<template>
  <RevLoadingScreen
    v-if="isLoadingMetadata && isLoadingConversation"
    :text="i18n(translations.loading)"
  />
  <div
    v-else
    class="flex h-[calc(100vh-5.2rem)] flex-col md:h-[calc(100vh-6.1rem)]"
  >
    <ConversationSummary
      v-if="metadata"
      :backLink="metadata.backLink"
      class="bg-static-default-low shrink-0"
      :counterOfferPrice="metadata.counterOfferPrice"
      :image="metadata.image"
      :price="metadata.price"
      :recipient="metadata.recipient"
      :status="metadata.status"
      :title="metadata.title"
    />
    <Conversation
      v-if="conversation"
      class="shrink overflow-y-auto"
      :is-loading-conversation
      :is-loading-more
      :messages="conversation.messages"
      :role
      @load="loadMore"
    />
    <ConversationBar
      v-if="metadata?.showMessageBar"
      v-model:message="messageInput"
      class="shrink-0"
      :is-loading-conversation
      :is-loading-post-message
    />
  </div>
</template>

<script setup lang="ts">
import { useRouteParams } from '@backmarket/nuxt-layer-base/composables/useRouteParams'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import { useConversation } from '@backmarket/nuxt-layer-buyback/composables/conversation/useConversation'

import Conversation from '../Conversation/Conversation.vue'
import ConversationBar from '../ConversationBar/ConversationBar.vue'
import ConversationSummary from '../ConversationSummary/ConversationSummary.vue'

import { type Role } from './OrderConversation.constants'
import translations from './OrderConversation.translations'

const { role } = defineProps<{
  role: Role
}>()

const { id } = useRouteParams()
const i18n = useI18n()

const {
  conversation,
  metadata,
  isLoadingMore,
  isLoadingConversation,
  isLoadingMetadata,
  isLoadingPostMessage,
  messageInput,
  loadMore,
} = useConversation(role, id)
</script>
