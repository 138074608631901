<template>
  <div ref="root">
    <slot :in-viewport />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref } from 'vue'

import { useIntersectionObserver } from '@vueuse/core'

const emit = defineEmits<{
  (e: 'enter'): void
  (e: 'exit'): void
}>()

const inViewport = ref(false)
const root = ref<HTMLDivElement | null>(null)

const { stop } = useIntersectionObserver(root, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    emit('enter')
    inViewport.value = true
  } else {
    emit('exit')
    inViewport.value = false
  }
})

onBeforeUnmount(() => {
  stop()
})
</script>
