<template>
  <component
    :is="componentType"
    class="flex h-full"
    :rel="link?.rel"
    :target="link?.target"
    :to="link?.href"
    @click="onGlobalLinkClick"
  >
    <Background
      :background
      class="w-256"
      :class="{
        'w-256 md:w-[352px]': fixedWidth && size === 'medium',
        'w-256': fixedWidth && size === 'small',
        'p-16': withPadding,
        'shadow-short': !!background,
      }"
      container-class="flex flex-col gap-24 overflow-hidden"
      data-test="modular-card-container"
      rounded="always"
    >
      <ModularCardSection
        v-for="(section, sectionIndex) in parsedSections"
        :key="sectionIndex"
        v-bind="section"
        :blockPosition
        :cardPosition
        class="w-full"
        :data-test="`section-container-${sectionIndex}`"
      />
    </Background>
  </component>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Block } from '@backmarket/http-api/src/api-specs-content/models/block'
import type { CmsButtonProps } from '@backmarket/http-api/src/api-specs-content/models/cms-button'
import {
  type ModularCard,
  type ModularCardImageProps,
} from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import Background from '@backmarket/nuxt-layer-cms/Background.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'

import type { MediaBlockProps } from '~/scopes/cms/blocks/Media/Media.vue'

import ModularCardSection from './ModularCardSection.vue'

export type ModularCardProps = Omit<ModularCard, 'id'> & {
  cardPosition: number
  fixedWidth?: boolean
  withPadding?: boolean
}

const props = withDefaults(
  defineProps<ModularCardProps & ContentBlockProps>(),
  {
    fixedWidth: true,
    withPadding: true,
  },
)

const { trackClick } = useTracking()

const ignoreLinksBlocks = new Set([
  'Media',
  'RichText',
  'ModularCardImage',
  'Button',
])

const parsedSections = computed(() => {
  if (!props.link) return props.sections

  return props.sections.map((section) => {
    const parsedSlots = section.slots.map((slot) => {
      if (!ignoreLinksBlocks.has(slot.type)) return slot

      if (slot.type === 'Button') {
        const slotProps = slot.props as CmsButtonProps
        slotProps.ctaLink.link.href = ''

        return { ...slot, props: slotProps }
      }

      const blockProps = { id: slot.id, hiddenDevices: slot.hiddenDevices }

      if (slot.type === 'RichText') {
        const richTextProps = slot.props as RichTextProps

        return {
          ...blockProps,
          type: slot.type,
          props: { ...richTextProps, ignoreLinks: true },
        } satisfies Block<'RichText', RichTextProps>
      }

      if (slot.type === 'Media') {
        const mediaProps = slot.props as MediaBlockProps

        return {
          ...blockProps,
          type: slot.type,
          props: { ...mediaProps, link: undefined, ignoreLinks: true },
        } satisfies Block<'Media', MediaBlockProps>
      }

      const slotProps = slot.props as ModularCardImageProps
      slotProps.link = undefined

      return { ...slot, props: slotProps }
    })

    return { ...section, slots: parsedSlots }
  })
})

const componentType = computed(() => (props.link ? RevButtonBase : 'div'))

function onGlobalLinkClick() {
  if (componentType.value === 'div') return

  trackClick({
    position: props.cardPosition,
    zone: props.tracking?.zone || '',
    value: 'ModularCard',
    block_position: props.blockPosition || 1,
    technical_name: props.technicalName || '',
  })
}

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])
</script>
