import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

import type { GetLiveIdentityCheckConsentResponse } from '~/api/customer-delivery/live-identity-check/consent/useGetLiveIdentityCheckConsent/useGetLiveIdentityCheckConsent.api.types'

export const ENDPOINT =
  '/customer-delivery/api/live-identity-check/:publicId/consent'

export function useGetLiveIdentityCheckConsent() {
  const $httpFetch = useHttpFetch()

  return (publicId: string, signal?: AbortSignal) =>
    $httpFetch<GetLiveIdentityCheckConsentResponse>(ENDPOINT, {
      pathParams: { publicId },
      method: 'GET',
      signal,
    })
}
