<template>
  <div class="flex h-[100vh] items-center justify-center">
    <RevLoadingScreen
      v-show="!isLiveIdentityCheckConsentAwaited"
      class="fixed inset-0"
      :text="i18n(translations.loading)"
    />
    <div
      v-show="isLiveIdentityCheckConsentAwaited"
      class="flex flex-col md:flex-row md:items-center lg:w-[1120px]"
    >
      <RevIllustration
        alt=""
        class="shrink-0 self-center"
        :height="282"
        src="/img/common/loading-zeloot.svg"
        :width="282"
      />
      <RevForm
        class="flex flex-1 shrink-0 flex-col items-stretch gap-32 p-32"
        :initial-values
        @submit.prevent=""
      >
        <template #default="{ values }">
          <h1 class="heading-1">{{ i18n(translations.disclaimerTitle) }}</h1>
          <p class="whitespace-pre-wrap">
            {{ i18n(translations.disclaimerBody) }}
          </p>
          <div
            class="bg-surface-default-hi border-static-default-mid rounded-md max-h-224 overflow-y-auto border-1 p-12"
          >
            <BlockRenderer
              v-for="(block, index) in content?.blocks"
              :id="block.id"
              :key="block.id"
              :block-position="index"
              :hidden-devices="[]"
              :props="block.props"
              :type="block.type"
              :with-bottom-margin="false"
            />
          </div>
          <RevCheckbox
            id="live-identity-check-grant-consent"
            v-model="values.grantConsent"
          >
            <template #label>
              {{ i18n(translations.grantConsentCheckboxLabel) }}
            </template>
          </RevCheckbox>
          <div class="flex flex-col gap-32 md:flex-row">
            <RevButton
              class="flex-1 shrink-0"
              :disabled="loading"
              :loading
              type="button"
              variant="secondary"
              @click="refuseConsent"
            >
              {{ i18n(translations.doNotProceed) }}
            </RevButton>
            <RevButton
              class="flex-1 shrink-0"
              :disabled="loading || !values.grantConsent"
              :loading
              type="button"
              variant="primary"
              @click="grantConsent"
            >
              {{ i18n(translations.proceed) }}
            </RevButton>
          </div>
        </template>
      </RevForm>
    </div>
  </div>
</template>

<script setup lang="ts">
import { navigateTo, useNoIndexMeta, useRouteParams } from '#imports'
import { computed, onUnmounted, reactive, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButton } from '@ds/components/Button'
import { RevCheckbox } from '@ds/components/Checkbox'
import { RevForm } from '@ds/components/Form'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import {
  type LiveIdentityCheckConsentExpired,
  type LiveIdentityCheckConsentStatus,
  type LiveIdentityCheckConsentUpdated,
  isLiveIdentityCheckConsentGranted,
} from '~/api/customer-delivery/live-identity-check/consent/liveIdentityCheckConsent.types'
import { useUpdateLiveIdentityCheckConsent } from '~/api/customer-delivery/live-identity-check/consent/useUpdateLiveIdentityCheckConsent/useUpdateLiveIdentityCheckConsent.api'
import BlockRenderer from '~/scopes/cms/shared-components/BlockRenderer/BlockRenderer.vue'
import { useGetContentLegalPageLiveIdentityCheckDataProcessConsent } from '~/scopes/customer-delivery/pages/LiveIdentityCheckConsent/composables/useGetContentLegalPageLiveIdentityCheckDataProcessConsent'
import { useLiveIdentityCheckConsent } from '~/scopes/customer-delivery/pages/LiveIdentityCheckConsent/composables/useLiveIdentityCheckConsent'
import { HOME } from '~/scopes/home/route-names'

import translations from './LiveIdentityCheckConsent.translations'

const i18n = useI18n()
const logger = useLogger()
useNoIndexMeta()

const { publicId } = useRouteParams()
const loading = ref<boolean>(false)

const initialValues = reactive<{ grantConsent: boolean }>({
  grantConsent: false,
})

const { openErrorToast } = useTheToast()
const { content } = useGetContentLegalPageLiveIdentityCheckDataProcessConsent()
const updateLiveIdentityCheckConsent = useUpdateLiveIdentityCheckConsent()

async function redirectToHome() {
  await navigateTo(
    { name: HOME },
    {
      replace: true,
    },
  )
}

async function redirectToIdentityCheckProvider({
  identityCheckLink,
}: LiveIdentityCheckConsentUpdated | LiveIdentityCheckConsentExpired) {
  if (identityCheckLink) {
    await navigateTo(identityCheckLink, {
      external: true,
      open: {
        target: '_self',
        windowFeatures: {
          noopener: true,
          noreferrer: true,
        },
      },
      replace: true,
    })
  }
}

const liveIdentityCheckStatus = await useLiveIdentityCheckConsent(
  redirectToIdentityCheckProvider,
  redirectToHome,
  redirectToHome,
)
const abortController = ref<AbortController | null>(null)
const isLiveIdentityCheckConsentAwaited = computed(
  () => liveIdentityCheckStatus === 'awaited',
)

async function handleLiveIdentityCheckConsent(
  response: LiveIdentityCheckConsentUpdated | LiveIdentityCheckConsentExpired,
) {
  if (isLiveIdentityCheckConsentGranted(response)) {
    await redirectToIdentityCheckProvider(response)
  } else {
    await redirectToHome()
  }
}

async function registerConsent(
  consent: LiveIdentityCheckConsentStatus,
): Promise<void> {
  abortController.value = new AbortController()
  loading.value = true

  try {
    const response = await updateLiveIdentityCheckConsent(
      publicId,
      {
        action: consent,
      },
      abortController.value.signal,
    )

    await handleLiveIdentityCheckConsent(response)
  } catch (err) {
    loading.value = false
    if (abortController.value.signal.aborted) {
      // component unmounted do nothing
    } else {
      openErrorToast()
      const error = err as Error
      logger.error(`[LiveIdentityCheckConsent] ${error.message}`, {
        error,
        owners: ['bot-squad-delivery-experience-front'],
      })
    }
  }
}

async function refuseConsent() {
  return registerConsent('REFUSE_CONSENT')
}

async function grantConsent() {
  return registerConsent('GRANT_CONSENT')
}

onUnmounted(() => {
  abortController.value?.abort()
})
</script>
