<template>
  <RevButtonBase
    class="text-static-default-hi rounded-md flex items-center justify-center gap-8 p-4 hover:text-action-default-hi-hover"
    data-test="country-switch-menu-button"
    @click="
      () => {
        trackButtonClick()
        $emit('click')
      }
    "
  >
    <RevCountryFlag v-if="countryCode" class="rounded-xs" :country-code />
    <div class="body-2-bold">
      {{ countryCode }}
    </div>
  </RevButtonBase>
</template>

<script setup lang="ts">
import { type CountryCodeValue } from '@backmarket/http-api'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCountryFlag } from '@ds/components/CountryFlag'

const { countryCode, trackingSpot } = defineProps<{
  countryCode: CountryCodeValue
  trackingSpot: 'header' | 'nav'
}>()

const { trackCountrySwitchClick } = useTracking()

const trackButtonClick = () => {
  trackCountrySwitchClick({
    name: 'country_switch',
    zone: trackingSpot,
  })
}

defineEmits(['click'])
</script>
