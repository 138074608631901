`
<template>
  <RevIllustration
    :alt
    class="h-[46px] w-[46px] object-contain"
    :height="46"
    :src="toPublicPath('/img/footer/best-e-commerce-website.jpg')"
    :width="46"
  />
</template>

<script setup lang="ts">
import { toPublicPath } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Footer.translations'

const i18n = useI18n()

const alt = i18n(translations.bestECommerceWebsiteLogoAlt)
</script>
