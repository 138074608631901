export default {
  title: {
    id: 'country_switch_menu_title',
    defaultMessage: 'Choose location',
  },
  description: {
    id: 'country_switch_menu_description',
    defaultMessage:
      'Changing your location might affect your delivery address options, price, product availability, and currency.',
  },
  searchCountryLabel: {
    id: 'country_switch_menu_search_country_label',
    defaultMessage: 'Search your country',
  },
  currentLocationTitle: {
    id: 'country_switch_menu_current_location_title',
    defaultMessage: 'Current location',
  },
  currentAllMarketsTitle: {
    id: 'country_switch_menu_current_all_markets_title',
    defaultMessage: 'Countries',
  },
  redirectionBannerLabel: {
    id: 'country_switch_redirection_banner_label',
    defaultMessage: 'Want to shop on our {marketPlace} marketplace instead?',
  },
  redirectionBannerUpdateButton: {
    id: 'country_switch_redirection_banner_update_button',
    defaultMessage: 'Visit',
  },
}
