<template>
  <RevCardCarousel
    v-if="hasArticles"
    :next-alternative-text="i18n(translations.nextSlideText)"
    :prev-alternative-text="i18n(translations.prevSlideText)"
    :subtitle
    :title
  >
    <ArticleCard
      v-for="(article, index) in articles"
      :key="article.id"
      class="mb-16"
      data-test="article-card"
      v-bind="article"
      :tracking="trackingData({ index })"
    />

    <template v-if="cta" #button>
      <RevButton
        :icon="IconArrowRight"
        :rel="cta?.link?.rel"
        size="medium"
        :target="cta?.link?.target"
        :to="cta?.link?.href"
        variant="primary"
      >
        {{ cta?.label }}
      </RevButton>
    </template>
  </RevCardCarousel>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { IconArrowRight } from '@ds/icons/IconArrowRight'

import type { ArticleCard as ArticleCardType } from './ArticleCard.types'
import ArticleCard from './ArticleCard.vue'
import translations from './ArticleCardsCarousel.translations'

const props = withDefaults(
  defineProps<{
    articles: ArticleCardType[]
    tracking?: Tracking
    title?: string
    subtitle?: string
    cta?: CTALink
  }>(),
  {
    tracking: () => ({}),
    title: undefined,
    subtitle: undefined,
    cta: undefined,
  },
)

const i18n = useI18n()

const hasArticles = computed(() => props.articles.length > 0)

const trackingData = ({ index }: { index: number }) => ({
  ...props.tracking,
  position: index + 1,
})
</script>
