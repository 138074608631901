<template>
  <div v-if="hasProducts" class="w-full">
    <ProductCardCollection
      :block-position
      :cta="ctaLink"
      layout="carousel"
      :products="products || []"
      provider="algolia"
      :subtitle
      :title
      :tracking="{
        productComponent: 'Products',
        ...tracking,
      }"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import type { ProductsBlockApiProps } from '@backmarket/http-api/src/api-specs-content/models/products-block'
import ProductCardCollection from '@backmarket/nuxt-layer-recommendation/ProductCardCollection.vue'

import type { ContentBlockProps } from '../../models/content-block'
import { useSearchProducts } from '../../products/useSearchProducts'

export type ProductsBlockProps = Partial<ProductsBlockApiProps> &
  ContentBlockProps
const props = withDefaults(defineProps<ProductsBlockProps>(), {
  productQueries: () => [],
  title: '',
  subtitle: '',
})

// This event is common to every CMS block in order to hide the block containers
// on demand
const emits = defineEmits(['error'])

const { data: products, error } = useSearchProducts(props)

watch(products, () => {
  if (!products.value || products.value.length === 0) {
    emits('error')
  }
})

watch(error, () => {
  if (error.value) emits('error')
})

const hasProducts = computed(() => products.value && products.value.length > 0)
</script>
