import { type Ref, computed } from 'vue'

import type { Price } from '@backmarket/http-api'

import { useBuybackOffer } from './useBuybackOffer'

export function useDiscountedPrice<T extends { price: Price }>(
  elements: Ref<T[] | T | null | undefined>,
) {
  const { getDiscountedPriceForProductCard } = useBuybackOffer()
  const products = computed(() => {
    if (Array.isArray(elements.value)) {
      return elements.value.map((element) => {
        const discountedPrice = getDiscountedPriceForProductCard(element.price)

        return { ...element, discountedPrice }
      })
    }

    return []
  })
  const product = computed(() => {
    if (elements.value && !Array.isArray(elements.value)) {
      const discountedPrice = getDiscountedPriceForProductCard(
        elements.value.price,
      )

      return { ...elements.value, discountedPrice }
    }

    return null
  })

  return { products, product }
}
