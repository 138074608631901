import type { RouteRecordRaw } from 'vue-router'

import { CMS } from '../cms/routes-names'

export const PDF = {
  TERMS_MERCHANT_ONBOARDING: 'terms_merchant_onboarding',
  CERTIFICATE_TO_TRANSPORT_INVESTIGATION:
    'certificate_to_transport_investigation',
}

export const LEGAL = {
  GET_VISITOR_ID: 'get_visitor_id',
  COOKIES: 'cookies',
  REFER_FRIEND: 'refer_friend_terms_conditions',
}

export default (): RouteRecordRaw[] => {
  return [
    {
      path: 'cookies',
      name: LEGAL.COOKIES,
      component: () => import('./pages/Cookies.vue'),
    },
    {
      path: 'get-my-visitor-id',
      name: LEGAL.GET_VISITOR_ID,
      component: () => import('./pages/VisitorId.vue'),
    },
    {
      path: 'legal/previous/:pageName',
      component: () => import('../cms/Landing.vue'),
      children: [
        {
          name: CMS.LEGAL_PREVIOUS_VERSION,
          path: '',
          component: () => import('./pages/LegalTemplate/LegalTemplate.vue'),
          meta: {
            noCookieModal: true,
          },
        },
      ],
    },
    {
      path: 'legal/:pageName',
      component: () => import('../cms/Landing.vue'),
      children: [
        {
          name: CMS.LEGAL_PAGE,
          path: '',
          component: () => import('./pages/LegalTemplate/LegalTemplate.vue'),
          meta: {
            noCookieModal: true,
          },
        },
      ],
    },
  ]
}
