<template>
  <RevList :has-external-borders="false">
    <PromoCodePerk
      :body="i18n(translations.mexicoAndCanadaAccordionBody)"
      :title="i18n(translations.mexicoAndCanadaAccordionTitle)"
    />
  </RevList>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'

import PromoCodePerk from '../../../PromoCodePerk/PromoCodePerk.vue'

import translations from './InternationalPerksList.translations'

const i18n = useI18n()
</script>
