import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { createIdentificationUniqueId } from '@backmarket/nuxt-module-identification/createIdentificationUniqueId'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { useHttpStore } from './composables/useHttpStore'
import { HEADERS } from './constants'
import { createHttpFetch } from './utils/createHttpFetch'

export default defineNuxtPlugin({
  name: 'http-v2-client',
  setup() {
    const config = useRuntimeConfig().public.http2
    const { market } = useMarketplace()
    const locale = useI18nLocale()

    window.$httpFetch = createHttpFetch({
      getDefaultBaseUrl() {
        return config.usePreprodApi
          ? `https://preprod${market.domain}`
          : config.baseUrlClient
      },

      getDefaultHeaders() {
        const httpStore = useHttpStore()
        const visitorId = useVisitorId()

        const headers = {
          [HEADERS.ACCEPT_LANGUAGE]: toRfc4646Locale(locale),
          [HEADERS.CSRF_TOKEN]: httpStore.csrf ?? '',
          [HEADERS.ENCODING]: 'gzip, deflate',
          [HEADERS.IDENTIFICATION_REQUEST_ID]: createIdentificationUniqueId(),
          [HEADERS.IDENTIFICATION_VISITOR_ID]: visitorId,
          [HEADERS.MARKET_COUNTRY_CODE]: market.countryCode,
          [HEADERS.MARKET_MARKETPLACE]: market.marketplace,
          [HEADERS.APP_PLATFORM]: 'web',
        }

        return headers
      },
    })
  },
})
