<template>
  <RecommendationCarousel
    :class="attrs.class"
    :cta="ctaLink"
    :recommendation-query="{
      widgetId: widgetId,
      limit: 16,
      personalisation: true,
    }"
    :subtitle
    :title
    :tracking-data
    @error="onCarouselNoResults"
  />
</template>

<script setup lang="ts">
import { computed, useAttrs } from 'vue'

import type { RecommendationsApiProps } from '@backmarket/http-api/src/api-specs-content/models/recommendations'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import {
  type ExtraTrackingData,
  useListTrackingData,
} from '@backmarket/nuxt-layer-recommendation/useProductTracking'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

export type RecommendationsProps = RecommendationsApiProps

const attrs = useAttrs()

const props = defineProps<ContentBlockProps & RecommendationsProps>()
const listTrackingData = useListTrackingData({
  blockPosition: props.blockPosition,
})

const trackingData = computed<ExtraTrackingData>(() => ({
  productComponent: 'Recommendations',
  list: listTrackingData,
  ...props.tracking,
}))

// This event is common to every CMS block in order to hide the block containers
// on demand
const emit = defineEmits(['error'])

const onCarouselNoResults = () => {
  emit('error')
}
</script>
