import { type MaybeRefOrGetter, toValue } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { retry } from '@backmarket/utils/function/retry'
import {
  type UseScriptTagOptions as VueUseScriptTagOptions,
  tryOnMounted,
  useScriptTag as vueUseScriptTag,
} from '@vueuse/core'

const USE_SCRIPT_TAG_LOG_TYPES = {
  LOAD: 'SCRIPT_TAG_LOAD',
  RETRY: 'SCRIPT_TAG_RETRY',
  ERROR: 'SCRIPT_TAG_ERROR',
}

export type UseScriptTagOptions = VueUseScriptTagOptions & {
  maxAttempts?: number
  retryDelay?: number
}

const DEFAULT_SCRIPT_ATTEMPTS = 5
const DEFAULT_SCRIPT_DELAY = 1000

/**
 * Creates a `<script>` tag, with support for automatically unloading (deleting)
 * the script tag on unmount.
 *
 * If a script tag already exists for the given URL, `useScriptTag()` will not
 * create another script tag, but keep in mind that depending on how you use it,
 * useScriptTag() might have already loaded then unloaded that particular JS
 * file from a previous call of useScriptTag().
 *
 * This composable is based on {@link https://vueuse.org/core/useScriptTag/ VueUse useScriptTag},
 * and supports the same options.
 *
 * Additionally to the default implementation, it will try to reload the script
 * when it fails. This can be tuned with the `maxAttempts` and `retryDelay`
 * options.
 *
 * Also, it will automatically send logs on failed attempts:
 * - `INFO` level with `type: SCRIPT_TAG_RETRY` on the first failed attempts
 * - `ERROR` level with `type: SCRIPT_TAG_ERROR` on the last failed attempt
 *
 * @example
 * // Load on mount, unload on unmount (simplest usage)
 * useScriptTag(
 *   'https://example.org/script.js',
 *   () => { console.log('Script loaded successfully') }
 * )
 *
 * @example
 * // Load manually, unload on unmount (recommended for error handling)
 * const { load } = useScriptTag(
 *   'https://example.org/script.js',
 *   noop,
 *   { immediate: false },
 * )
 *
 * try {
 *   await load()
 *   console.log('Script loaded successfully')
 * } catch (error) {
 *   console.log('Script failed to load')
 * }
 *
 * @example
 * // Load and unload manually (edge cases only)
 * const { load, unload } = useScriptTag(
 *   'https://example.org/script.js',
 *   noop,
 *   { manual: true },
 * )
 *
 * try {
 *   await load()
 *   console.log('Script loaded successfully')
 * } catch (error) {
 *   console.log('Script failed to load')
 * }
 *
 * unload()
 *
 * @param src The src prop of the `<script>` tag
 * @param onLoaded Callback executed once the script is successfully loaded
 * @param options {@link https://vueuse.org/core/useScriptTag/ VueUse useScriptTag} options, with the additional:
 * @param options.maxAttempts Number maximum of attempts to load the script (default: 5)
 * @param options.retryDelay Delay between two attempts (default: 1000 ms)
 */
export function useScriptTag(
  src: MaybeRefOrGetter<string>,
  onLoaded?: (el: HTMLScriptElement) => void | undefined,
  options: UseScriptTagOptions = {},
) {
  const {
    maxAttempts = DEFAULT_SCRIPT_ATTEMPTS,
    retryDelay = DEFAULT_SCRIPT_DELAY,
    ...vueUseOptions
  } = options

  const logger = useLogger()

  const result = vueUseScriptTag(src, onLoaded, {
    ...vueUseOptions,

    // See `tryOnMounted` call below
    immediate: false,
  })

  function onFailedAttempt(
    cause: unknown,
    failedAttempts: number,
    remainingAttempts: number,
  ) {
    const attributes = {
      cause,
      failedAttempts,
      options,
      remainingAttempts,
      src: toValue(src),
    }

    if (remainingAttempts === 0) {
      logger.error(
        `[nuxt-layer-base] Failed to load script after ${failedAttempts} attempts`,
        {
          type: USE_SCRIPT_TAG_LOG_TYPES.ERROR,
          error: new Error(
            `Failed to load script after ${failedAttempts} attempts`,
          ),
          ...attributes,
          owners: ['bot-chapter-frontend-core-pastrami'],
        },
      )
    } else {
      logger.info('[nuxt-layer-base] Failed to load script, retrying...', {
        type: USE_SCRIPT_TAG_LOG_TYPES.RETRY,
        ...attributes,
        owners: ['bot-chapter-frontend-core-pastrami'],
      })
    }

    result.unload()
  }

  const loadWithRetries = (...args: Parameters<typeof result.load>) => {
    logger.info(`[nuxt-layer-base] Loading script ${src}`, {
      type: USE_SCRIPT_TAG_LOG_TYPES.LOAD,
      options,
      src,
      owners: ['bot-chapter-frontend-core-pastrami'],
    })

    return retry(async () => result.load(...args), {
      count: maxAttempts,
      delay: retryDelay,
      onFailedAttempt,
    })
  }

  // We call @vueuse's useScriptTag with `immediate:false`. This way, we can
  // make the `tryOnMounted` call ourselves, passing the `loadWithRetries`
  // function (instead of the `load` function).
  // See: https://github.com/vueuse/vueuse/blob/main/packages/core/useScriptTag/index.ts
  // See: https://github.com/vueuse/vueuse/blob/main/packages/shared/tryOnMounted/index.ts
  if (options.immediate !== false && !options.manual) {
    tryOnMounted(loadWithRetries)
  }

  return {
    ...result,
    load: loadWithRetries,
  }
}
