<template>
  <RevCard
    class="flex flex-col items-center px-24 pb-32 pt-56 lg:flex-row lg:p-56"
  >
    <div
      class="border-static-default-hi mood-cornflower rounded-full relative h-96 w-96 min-w-96 overflow-hidden border-4 lg:mr-56"
    >
      <RevIllustration
        :alt="image?.alt"
        class="absolute min-h-full w-auto object-cover"
        :height="96"
        :src="image?.src"
        :width="96"
      />
    </div>

    <div class="mt-24 flex flex-col gap-24 text-center lg:mt-0 lg:text-left">
      <div>
        <h2 class="heading-2 flex flex-col lg:flex-row lg:flex-wrap">
          <span>
            <span>
              {{ i18n(translations.title, { name }) }}
            </span>
            <span v-if="hasRole">,&nbsp;</span>
          </span>
          <span v-if="hasRole">{{ role }}</span>
        </h2>

        <p v-if="hasPresentation" class="text-static-default-low body-1">
          {{ presentation }}
        </p>
      </div>

      <ul v-if="profiles.length > 0" class="flex items-center gap-8">
        <template v-for="(profile, index) in profilesWithIconPath">
          <RevButtonBase
            v-if="profile.iconPath"
            :key="profile.icon"
            rel="nofollow"
            target="_blank"
            :to="profile.link?.href"
            @click="() => trackSocialProfileClick({ profile, index })"
          >
            <RevIllustration
              :alt="profile.icon"
              class="size-24"
              :height="24"
              :src="profile.iconPath"
              :width="24"
            />
          </RevButtonBase>
        </template>
      </ul>

      <slot></slot>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { SocialProfile } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import type { Image } from '@backmarket/http-api/src/api-specs-content/models/image'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './Author.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    name: string
    role?: string
    presentation?: string
    image: Image
    profiles?: SocialProfile[]
    tracking?: Tracking
  }>(),
  {
    role: '',
    presentation: '',
    profiles: () => [],
    tracking: () => ({}),
  },
)
const getSocialIconPath = (icon: SocialProfile['icon']) => {
  switch (icon) {
    case 'facebook':
      return '/img/socials/facebook.svg'

    case 'twitter':
      return '/img/socials/twitter.svg'

    case 'instagram':
      return '/img/socials/instagram.svg'

    case 'linkedin':
      return '/img/socials/linkedin.svg'

    case 'pinterest':
      return '/img/socials/pinterest.svg'

    case 'tiktok':
      return '/img/socials/tiktok.svg'

    case 'medium':
      return '/img/socials/medium.svg'

    case 'link':
      return '/img/socials/link.svg'

    case 'profile':
      return '/img/socials/profile.svg'

    case 'youtube':
      return '/img/socials/youtube.svg'

    default:
      return ''
  }
}

const { trackClick } = useTracking()

function trackSocialProfileClick({
  profile,
  index,
}: {
  profile: SocialProfile
  index: number
}) {
  trackClick({
    value: 'Author',
    name: profile.icon,
    position: index + 1,
    ...props.tracking,
  })
}

const profilesWithIconPath = computed(() =>
  props.profiles.map((profile) => ({
    ...profile,
    iconPath: getSocialIconPath(profile.icon),
  })),
)

const hasPresentation = computed(() => !!props.presentation)
const hasRole = computed(() => !!props.role)
</script>
