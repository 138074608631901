import validate from "/var/www/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/var/www/node_modules/@backmarket/nuxt-layer-oauth/src/middleware/auth.global.ts";
import guest_45global from "/var/www/node_modules/@backmarket/nuxt-layer-oauth/src/middleware/guest.global.ts";
import payment_45subdomain_45global from "/var/www/node_modules/@backmarket/nuxt-layer-payment/middleware/paymentSubdomain.global.ts";
import log_45ds_45version_45global from "/var/www/node_modules/@backmarket/nuxt-layer-design-system/middleware/logDsVersion.global.ts";
import _01_45client_45side_45navigation_45global from "/var/www/node_modules/@backmarket/nuxt-layer-base/middleware/01.clientSideNavigation.global.ts";
import logger from "/var/www/packages/nuxt-module-logger/src/runtime/middleware.ts";
import instrumentation_46setResourceName from "/var/www/packages/nuxt-module-instrumentation/src/runtime/middleware/setResourceName.ts";
import manifest_45route_45rule from "/var/www/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  guest_45global,
  payment_45subdomain_45global,
  log_45ds_45version_45global,
  _01_45client_45side_45navigation_45global,
  logger,
  instrumentation_46setResourceName,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "fetch-user": () => import("/var/www/apps/front-office/middleware/fetchUser.ts"),
  "log-attempt": () => import("/var/www/apps/front-office/middleware/logAttempt.ts"),
  "redirect-bouygues-mobile-plan": () => import("/var/www/apps/front-office/middleware/redirectBouyguesMobilePlan.ts"),
  "redirect-if-address-needs-bouygues-normalization": () => import("/var/www/apps/front-office/middleware/redirectIfAddressNeedsBouyguesNormalization.ts"),
  "redirect-if-cart-empty": () => import("/var/www/apps/front-office/middleware/redirectIfCartEmpty.ts"),
  "redirect-if-missing-collection-point": () => import("/var/www/apps/front-office/middleware/redirectIfMissingCollectionPoint.ts"),
  "redirect-if-missing-shipping": () => import("/var/www/apps/front-office/middleware/redirectIfMissingShipping.ts")
}