import type { BlockTypeProps } from '@backmarket/http-api/src/api-specs-content/models/block'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { objectEntries } from '@backmarket/utils/object/objectEntries'

import { transformHref } from './transformHref'

/**
 *
 * @param props - Content service block props
 * @param currentUrl - Current location URL
 * @returns Content service block props with relative links if they match the current location domain.
 *
 * @example
  buildPropsWithCorrectLinks({
    props: {
      primary: {
        link: {
          href: 'https://preprod.backmarket.com/en-us/e/good-deals',
        },
        label: 'Good deals',
      },
      secondary: {
        link: {
          href: 'https://www.trustpilot.com',
        },
        label: 'Trustpilot',
      },
    },
    currentUrl: new URL('https://www.backmarket.com/en-us'),
  })
  // returns:
  {
    primary: {
      link: {
        href: '/en-us/e/good-deals',
      },
      label: 'Good deals',
    },
    secondary: {
      link: {
        href: 'https://www.trustpilot.com',
        target: '_blank'
        rel: 'nofollow'
      },
      label: 'Trustpilot',
    },
  }
 */
export function buildPropsWithCorrectLinks<
  T extends BlockTypeProps | string | number | object,
>({ props, currentUrl }: { props: T; currentUrl: URL }): T {
  // for string, number or null, return the value as is
  if (typeof props !== 'object') return props

  const result: Record<PropertyKey, unknown> = {}

  for (const [name, value] of objectEntries(props)) {
    if (name === 'href') {
      const { href, target, rel } = transformHref({
        href: value as string,
        currentUrl,
      })
      result.href = href
      if (target) result.target = target
      if (rel) result.rel = rel
    } else if (name === 'uri') {
      const { href } = transformHref({ href: value as string, currentUrl })
      result.uri = href
    } else if (isEmpty(value) || typeof value !== 'object') {
      result[name] = value
    } else if (Array.isArray(value)) {
      result[name] = value.map((nestedProps) =>
        buildPropsWithCorrectLinks({
          props: nestedProps,
          currentUrl,
        }),
      )
    } else {
      result[name] = buildPropsWithCorrectLinks({
        props: value as object | string | number,
        currentUrl,
      })
    }
  }

  return result as T
}
