import type { QuizProps } from '@backmarket/http-api/src/api-specs-content/models/quiz'
import { objectEntries } from '@backmarket/utils/object/objectEntries'

export function mostFrequentItemInArray<T extends string | number>(arr: T[]) {
  const initialCountMap = {} as Record<T, number>
  const itemCountMap = arr.reduce((countMap, item) => {
    return {
      ...countMap,
      [item]: countMap[item] ? countMap[item] + 1 : 1,
    }
  }, initialCountMap)

  return objectEntries(itemCountMap).reduce(
    (entry, tmpMostFrequentEntry) =>
      tmpMostFrequentEntry[1] > entry[1] ? tmpMostFrequentEntry : entry,
    [arr[0], 0],
  )[0]
}

export function isEndOfQuiz({
  quiz,
  currentQuestionIndex,
}: {
  quiz: QuizProps
  currentQuestionIndex: number
}) {
  return currentQuestionIndex >= quiz.questions.length
}
