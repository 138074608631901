<template>
  <div v-if="hasProducts" class="w-full">
    <TitleCtaContainer :cta="ctaLink" :subtitle :title>
      <ProductCardCollection
        :block-position
        :layout
        :products="products || []"
        provider="algolia"
        :tracking="{
          productComponent: 'RefinedProducts',
          ...tracking,
        }"
      />
    </TitleCtaContainer>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'

import type { RefinedProductsApiProps } from '@backmarket/http-api/src/api-specs-content/models/refined-products'
import ProductCardCollection from '@backmarket/nuxt-layer-recommendation/ProductCardCollection.vue'

import type { ContentBlockProps } from '../../models/content-block'
import { useSearchProducts } from '../../products/useSearchProducts'
import TitleCtaContainer from '../../shared-components/TitleCtaContainer/TitleCtaContainer.vue'

export type RefinedProductsProps = RefinedProductsApiProps & ContentBlockProps

const props = withDefaults(defineProps<RefinedProductsProps>(), {
  ctaLink: undefined,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
const emits = defineEmits(['error'])

const { data: products, error } = await useSearchProducts({
  productQuery: props.productQuery,
})

const layout = computed(() =>
  props.productLayout === 'grid' ? 'grid' : 'carousel',
)

watch(products, () => {
  if (!products.value || products.value.length === 0) {
    emits('error')
  }
})

watch(error, () => {
  if (error.value) emits('error')
})

const hasProducts = computed(() => products.value && products.value.length > 0)
</script>
