import { useAsyncData, useNuxtApp } from '#imports'

import { useRouteParams } from '@backmarket/nuxt-layer-base/composables/useRouteParams'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import {
  type LiveIdentityCheckConsentGranted,
  type LiveIdentityCheckStatus,
  isLiveIdentityCheckConsentExpired,
  isLiveIdentityCheckConsentGranted,
  isLiveIdentityCheckConsentRefused,
} from '~/api/customer-delivery/live-identity-check/consent/liveIdentityCheckConsent.types'
import { useGetLiveIdentityCheckConsent } from '~/api/customer-delivery/live-identity-check/consent/useGetLiveIdentityCheckConsent/useGetLiveIdentityCheckConsent.api'

export async function useLiveIdentityCheckConsent(
  onConsentGranted: (
    liveIdentityCheckConsent: LiveIdentityCheckConsentGranted,
  ) => Promise<void>,
  onConsentRefusedOrExpired: () => Promise<void>,
  onError: () => Promise<void>,
): Promise<LiveIdentityCheckStatus> {
  const nuxtApp = useNuxtApp()
  const logger = useLogger()
  const { publicId } = useRouteParams()
  const { openErrorToast } = useTheToast()
  const getLiveIdentityCheckConsent = useGetLiveIdentityCheckConsent()

  const { data } = await useAsyncData(async () => {
    return getLiveIdentityCheckConsent(publicId)
  })

  if (data.value) {
    if (isLiveIdentityCheckConsentRefused(data.value)) {
      await nuxtApp.runWithContext(async () => {
        await onConsentRefusedOrExpired()
      })

      return 'refused'
    }
    if (isLiveIdentityCheckConsentExpired(data.value)) {
      await nuxtApp.runWithContext(async () => {
        await onConsentRefusedOrExpired()
      })

      return 'expired'
    }
    if (isLiveIdentityCheckConsentGranted(data.value)) {
      await onConsentGranted(data.value)

      return 'granted'
    }

    return 'awaited'
  }

  await nuxtApp.runWithContext(async () => {
    openErrorToast()
    const error = new Error(
      `Error while fetching live identity check consent for public id ${publicId}`,
    )
    logger.error(`[LiveIdentityCheckConsent] ${error.message}`, {
      error,
      owners: ['bot-squad-delivery-experience-front'],
    })
    await onError()
  })

  return 'error'
}
