<template>
  <div>
    <div id="skip-to-content-wrapper" class="fixed z-50"></div>
    <div id="toast-wrapper" class="fixed z-50">
      <TheToast to="#toast-wrapper" />
    </div>

    <CookiesModal />

    <ClientOnly>
      <SoftPrompt v-if="isSoftPromptAvailable" />
      <BrazePrompt v-else />
    </ClientOnly>

    <slot name="header">
      <TheHeader />
    </slot>

    <slot />

    <slot name="footer">
      <Footer />
    </slot>
  </div>
</template>

<script lang="ts" setup>
import { useHead, useRuntimeConfig } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import TheToast from '@backmarket/nuxt-module-toast/TheToast.vue'
import CookiesModal from '@backmarket/nuxt-module-tracking/CookiesModal.vue'

import Footer from '~/scopes/navigation/components/Footer/Footer.vue'

import { useAppsflyer } from '../scopes/appsflyer/composables/useAppsflyer'
import BrazePrompt from '../scopes/braze/BrazePrompt.vue'
import TheHeader from '../scopes/navigation/components/TheHeader.vue'
import SoftPrompt from '../scopes/prompt/SoftPrompt.vue'

import { SOFTPROMPT_WINDOW_HEIGHT_THRESHOLD } from './default.constants'

useHead({
  htmlAttrs: {
    lang: useI18nLocale(),
  },
})
useAppsflyer()

const { countryCode } = useMarketplace().market
const enabledCountries =
  useRuntimeConfig().public.FF_ENABLE_SOFT_PROMPT_BY_COUNTRY || ''

const windowHeight = ref(0)

const isSoftPromptAvailable = computed(() => {
  const isEnabledInCurrentCountry = enabledCountries.includes(countryCode)
  const isHighEnough = windowHeight.value >= SOFTPROMPT_WINDOW_HEIGHT_THRESHOLD

  return isEnabledInCurrentCountry && isHighEnough
})

onMounted(() => {
  windowHeight.value = window.innerHeight
})
</script>

<style>
/* Safari adds an Apple Smart Banner automatically because of the Universal links (See Doorman config: https://github.com/BackMarket/doorman/tree/77be9c7f8e2af4d0f0fc9fe1678ec8f39ac41c69/static/apple/universal-links)
   We want to hide it because we have our own custom Banner with Appsflyer
*/
[class*='apple-banner'] {
  display: none !important;
}
</style>
