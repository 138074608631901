import { useRouter } from '#imports'
import { type MaybeRefOrGetter, ref } from 'vue'

import type {
  QuizProfile,
  QuizProps,
} from '@backmarket/http-api/src/api-specs-content/models/quiz'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'

import { useSimpleQuiz } from './useSimpleQuiz'

export const ANIMATION_BEFORE_NEXT_QUESTION = 600
export const ANIMATION_BEFORE_NAVIGATING_TO_LANDING_PAGE_MS = 500

export const NO_PROFILE = false

export type QuizCurrentQuestionAnswer = QuizProfile | typeof NO_PROFILE

function setOptionalTimeout(fn: () => unknown, delayMs: number | false) {
  if (!delayMs) fn()
  else setTimeout(fn, delayMs)
}

function useCurrentQuestionAnswer() {
  const currentQuestionAnswer = ref<QuizCurrentQuestionAnswer>(NO_PROFILE)

  const resetAnswer = () => {
    currentQuestionAnswer.value = NO_PROFILE
  }

  const setAnswer = (answer: QuizProfile) => {
    currentQuestionAnswer.value = answer
  }

  return {
    currentQuestionAnswer,
    resetAnswer,
    setAnswer,
  }
}
export function useQuizWithTransitions(
  quiz: MaybeRefOrGetter<QuizProps>,
  options: { withTimeout: boolean } = { withTimeout: true },
) {
  const router = useRouter()

  const {
    storeProfileAnswer,
    incrementQuestionIndex,
    currentQuestion,
    currentQuestionLabel,
    getMostPopularProfile,
    getMostPopularProfileLink,
    goBackToPreviousQuestion,
    isEndOfQuiz,
    currentQuestionIndex,
  } = useSimpleQuiz(quiz)

  const isFetchingQuizResults = ref(false)

  const animatingBeforeNextQuestion = ref(false)

  function navigateToLandingPage() {
    isFetchingQuizResults.value = true

    const navigateToMostPopularProfile = async () => {
      const mostPopularProfileLink = getMostPopularProfileLink()
      await router.push(toNuxtLink(mostPopularProfileLink.href))
    }

    setOptionalTimeout(
      navigateToMostPopularProfile,
      options.withTimeout
        ? ANIMATION_BEFORE_NAVIGATING_TO_LANDING_PAGE_MS
        : false,
    )
  }

  const { currentQuestionAnswer, resetAnswer, setAnswer } =
    useCurrentQuestionAnswer()

  function answerQuestion({ answerIndex }: { answerIndex: number }) {
    storeProfileAnswer(answerIndex)
    const answerProfile = currentQuestion.value.answers[answerIndex].profile

    setAnswer(answerProfile)
    animatingBeforeNextQuestion.value = true

    setOptionalTimeout(
      () => {
        incrementQuestionIndex()
        resetAnswer()
        animatingBeforeNextQuestion.value = false
        if (isEndOfQuiz()) navigateToLandingPage()
      },
      options.withTimeout ? ANIMATION_BEFORE_NEXT_QUESTION : 0,
    )
  }

  function goBackToPreviousQuestionOverride() {
    resetAnswer()
    goBackToPreviousQuestion()
  }

  return {
    currentQuestion,
    answerQuestion,
    currentQuestionLabel,
    isFetchingQuizResults,
    getMostPopularProfile,
    getMostPopularProfileLink,
    goBackToPreviousQuestion: goBackToPreviousQuestionOverride,
    currentQuestionAnswer,
    animatingBeforeNextQuestion,
    currentQuestionIndex,
  }
}
