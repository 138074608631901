import { type MaybeRefOrGetter, computed, ref, toValue, watch } from 'vue'

import { type Locale, reviewsAPI } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import translations from './UserComment.translations'

export function useTranslateComment(
  review: MaybeRefOrGetter<{
    id: string
    translatedComment?: string
    comment: string
    languageCode: string
    countryCode: string
  }>,
) {
  const {
    market: { countryCode: currentCountryCode },
  } = useMarketplace()
  const i18n = useI18n()
  const locale = useI18nLocale()
  const logger = useLogger()
  const { openErrorToast } = useTheToast()

  const isTranslatable = computed(
    () => toValue(review).countryCode !== currentCountryCode,
  )
  const isTranslated = ref(!!toValue(review).translatedComment)
  const internalTranslatedComment = ref(toValue(review).translatedComment)

  watch(review, () => {
    isTranslated.value = !!toValue(review).translatedComment
    internalTranslatedComment.value = toValue(review).translatedComment
  })
  const mainReviewComment = computed<string>(() => {
    if (isTranslated.value && internalTranslatedComment.value)
      return internalTranslatedComment.value

    return toValue(review).comment
  })

  const language = computed(() => {
    if (isTranslated.value) {
      return i18n.language(toValue(review).languageCode as Locale)
    }

    const lang = locale.split('-')[0] || locale

    return i18n.language(lang as Locale)
  })

  const toggleTranslationLabel = computed(() => {
    return isTranslated.value
      ? i18n(translations.seeOriginalText)
      : i18n(translations.translateTextTo, { lang: language.value })
  })

  const translatedFrom = computed(() =>
    i18n(translations.textTranslatedFrom, { lang: language.value }),
  )

  async function fetchTranslationIfMissing({
    openToastOnError = false,
  }: { openToastOnError?: boolean } = {}) {
    // if translatedComment is already set, do not fetch again
    if (internalTranslatedComment.value) return

    try {
      const translation = await $httpFetch(reviewsAPI.getReviewV2, {
        pathParams: { reviewId: toValue(review).id },
        queryParams: { translation_locale: locale },
      })
      internalTranslatedComment.value = translation.translatedComment
    } catch (error) {
      logger.error(error as string, { owners: ['bot-squad-content-front'] })
      if (openToastOnError)
        openErrorToast({
          title: i18n(translations.errorToastTitle),
          content: i18n(translations.errorToastMessage),
        })
    }
  }

  async function toggleTranslation() {
    await fetchTranslationIfMissing({ openToastOnError: true })

    // only toggle if there is a translatedComment
    if (internalTranslatedComment.value) {
      isTranslated.value = !isTranslated.value
    }
  }

  return {
    mainReviewComment,
    isTranslated,
    toggleTranslation,
    toggleTranslationLabel,
    translatedFrom,
    isTranslatable,
  }
}
