<template>
  <section class="flex min-h-screen">
    <div class="bg-static-default-mid max-md:hidden md:w-[544px]">
      <RevCard class="mx-96 mt-64 flex items-center justify-between">
        <p class="body-1 py-20 pl-24">
          {{ i18n(translations.stepCategory) }}
        </p>
      </RevCard>
    </div>
    <div class="mx-auto w-full max-w-[620px] px-20 py-24 md:w-3/4 lg:w-full">
      <ClientOnly>
        <template #fallback>
          <RevSkeleton height="56px" shape="rectangle" width="100%" />
        </template>
        <RevStepper
          :active-step="i18n(translations.categoryNavigationTitle)"
          :alternative-text-back="i18n(translations.previousPage)"
          :alternative-text-close="i18n(translations.stepperClose)"
          :alternative-text-completed="i18n(translations.stepperCompleted)"
          :alternative-text-current="i18n(translations.stepperCurrent)"
          :close-button-label="i18n(translations.closeButtonLabel)"
          data-test="recycling-category-stepper"
          :steps
        />
      </ClientOnly>
      <h3 class="heading-3 mt-32">{{ i18n(translations.categoryTitle) }}</h3>
      <RevLink
        class="mt-16 no-underline"
        data-test="recycling-category-smartphone-link"
        :to="recyclingSmartphoneOfferRoute"
      >
        <RevCard class="mt-32 flex items-center justify-between">
          <p class="body-1 py-20 pl-12">
            {{ i18n(translations.categorySmartphone) }}
          </p>
          <RevIllustration
            :alt="i18n(translations.categorySmartphone)"
            data-test="recycling-category-smartphone-image"
            :height="48"
            src="/img/recycling/categorySmartphone.svg"
            :width="48"
          />
        </RevCard>
      </RevLink>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevSkeleton } from '@ds/components/Skeleton'
import { RevStepper } from '@ds/components/Stepper'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { ROUTES } from '~/scopes/recycling/routes-names'

import translations from './Recycling.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const recyclingSmartphoneOfferRoute = resolveLocalizedRoute({
  name: ROUTES.OFFER,
  params: {
    deviceCategory: 'smartphone',
  },
})

const steps = [
  i18n(translations.categoryNavigationTitle),
  i18n(translations.stepDevice),
  i18n(translations.stepShippingLabel),
].map((item) => ({
  label: item,
  name: item,
}))
</script>
