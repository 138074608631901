<template>
  <TitleCtaContainer :cta="ctaLink" :subtitle :title>
    <RevCardGrid :cards :layout="gridLayout">
      <template #default="{ card }">
        <ModularCard
          :key="card.id"
          :background="card.background"
          :blockPosition
          :card-position="cardPosition(card)"
          class="h-full w-full"
          :fixed-width="false"
          :link="card.link"
          :sections="card.sections"
          :size
          :technical-name="card.technicalName"
          :tracking
          :with-padding="cardsHavePadding"
        />
      </template>
    </RevCardGrid>
  </TitleCtaContainer>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import {
  type ModularCard as ModularCardT,
  type ModularCardsCarouselProps,
} from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import TitleCtaContainer from '@backmarket/nuxt-layer-cms/TitleCtaContainer.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { RevCardGrid } from '@ds/components/CardGrid'

import ModularCard from './ModularCard.vue'

const props = withDefaults(
  defineProps<Omit<ModularCardsCarouselProps, 'layout'> & ContentBlockProps>(),
  {
    mobileScreenColumns: 1,
  },
)

const cardPosition = (card: ModularCardT) =>
  props.cards.findIndex((c) => c.id === card.id) + 1
const gridLayout = computed(() => {
  return props.mobileScreenColumns === 2 ? 'fluid' : 'responsive'
})
</script>
