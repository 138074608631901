export const CMS = {
  ARTICLE: 'article',
  ARTICLE_ROOT_HUB: 'article-root-hub',
  ARTICLE_HUB: 'hub_buying_guide',
  BACK_STORIES: 'back-stories',
  BACK_STORIES_CATEGORY: 'back-stories-categories',
  BRAND_STORE: 'brand-store',
  BRAND_STORE_CATEGORY: 'brand-store-category',
  BUYBACK: 'buyback',
  BUYBACK_CATEGORY: 'buyback-category',
  CAREERS_HOME: 'careers-page',
  CAREERS_CATEGORY: 'careers-category',
  E2E_PAGE: 'CMS_E2E_PAGE',
  EVENT: 'event-page',
  GIFT_FINDER_EVENT: 'gift-finder-category',
  GIFT_FINDER_MAIN: 'gift-finder',
  LEGAL_PAGE: 'legal-page',
  LEGAL_PREVIOUS_VERSION: 'legal-document',
  REFER_FRIEND: 'refer-friend',
  SELLER: 'seller',
  SERVICE: 'service',
  STATEMENT: 'accessibility',
}
