import { useNuxtApp } from '#imports'
import type { RouteRecordRaw, RouterScrollBehavior } from 'vue-router'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { urlHashToObject } from '@backmarket/utils/url/urlHashToObject'
import type { RouterConfig } from 'nuxt/schema'

import aboutUs from '~/scopes/about-us/routes'
import adminRoutes from '~/scopes/admin/routes'
import authRoutes from '~/scopes/auth/routes'
import buybackRoutes from '~/scopes/buyback/routes'
import checkoutRoutes from '~/scopes/checkout/routes'
import cmsRoutes from '~/scopes/cms/routes'
import customerCareRoutes from '~/scopes/customer-care/routes'
import customerConversationRoutes from '~/scopes/customer-conversation/routes'
import customerDeliveryRoutes from '~/scopes/customer-delivery/routes'
import dashboardRoutes from '~/scopes/dashboard/routes'
import discountRoutes from '~/scopes/discount/routes'
import helpCenterRoutes from '~/scopes/help-center/routes'
import homeRoutes from '~/scopes/home/routes'
import insuranceRoutes from '~/scopes/insurance/routes'
import legalRoutes from '~/scopes/legal/routes'
import modelsComparisonRoutes from '~/scopes/models-comparison/routes'
import priceGuideRoutes from '~/scopes/price-guide/routes'
import productRoutes from '~/scopes/product/routes'
import productListRoutes from '~/scopes/product-list/routes'
import recyclingRoutes from '~/scopes/recycling/routes'
import repairRoutes from '~/scopes/repair/routes'
import reviewsRoutes from '~/scopes/reviews/routes'

import type { Features } from '../features'

// Function to control scrolling when navigating between pages.
// If a falsy value or an empty object is returned, no scrolling will happen.
export const scrollBehavior: RouterScrollBehavior = (
  to,
  from,
  savedPosition,
) => {
  // Returning the savedPosition will result in a native-like behavior when navigating with back/forward buttons:
  if (savedPosition) return savedPosition

  // We don't want to scroll if scroll hash papram is specified to 'false'
  const hash: Record<string, unknown[]> = urlHashToObject(to.hash)
  if (hash.scroll?.[0] === 'false') return false

  // We don't want to scroll if user navigate within the same page
  if (from.path === to.path) return false

  // We want to scroll as soon as page finished to load (when async data are fetched and ready)
  return new Promise((resolve) => {
    useNuxtApp().hooks.hookOnce('page:finish', () => {
      resolve({
        top: 0,
      })
    })
  })
}

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (fileSystemRoutes): readonly RouteRecordRaw[] => {
    const { features, market } = useMarketplace<Features>()
    const defaultLocale = toBcp47Locale(market.defaultLocale)
    const locales = market.locales
      .map((locale) => toBcp47Locale(locale))
      .join('|')

    return [
      ...fileSystemRoutes,
      { path: '/', redirect: `/${defaultLocale}` },
      {
        path: `/:locale(${locales})`,
        children: [
          ...reviewsRoutes({ market }),
          ...aboutUs(),
          ...buybackRoutes({ features }),
          ...cmsRoutes(),
          ...customerCareRoutes({ market }),
          ...customerConversationRoutes(),
          ...customerDeliveryRoutes(),
          ...helpCenterRoutes({ market }),
          ...homeRoutes(),
          ...legalRoutes(),
          ...modelsComparisonRoutes(),
          ...priceGuideRoutes(),
          ...productRoutes(),
          ...productListRoutes({ market }),
          ...dashboardRoutes({ market }),
          ...checkoutRoutes({ market }),
          ...repairRoutes({ market }),
          ...insuranceRoutes,
          ...recyclingRoutes,
          ...discountRoutes({ market }),
        ],
      },
      ...authRoutes({ market }),
      ...adminRoutes,
    ]
  },
  scrollBehavior,
}
