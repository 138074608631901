<template>
  <div v-if="items.length !== 0" ref="listbox">
    <div v-if="title" class="px-24" @mousedown.prevent="">
      <div class="flex items-center gap-8 py-8">
        <label
          class="text-static-default-low body-2-bold grow"
          for="search-bar-dropdown"
        >
          {{ title }}
        </label>
        <button
          v-if="withClear"
          class="text-action-default-low body-2 cursor-pointer"
          @click="cleanHandler"
        >
          {{ i18n(translations.clear) }}
        </button>
      </div>
    </div>
    <div
      id="search-bar-dropdown"
      class="divide-y-0"
      role="listbox"
      variant="compact"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        :aria-selected="selectedIndex === index"
        class="bg-static-default-min group relative hover:bg-static-default-min-hover"
        data-qa="search-bar-dropdown-item"
        data-test="search-bar-dropdown-item"
        role="option"
        tabindex="-1"
      >
        <button
          class="w-full px-24 py-12 pr-64 text-left"
          data-test="search-bar-dropdown-item-link"
          @mousedown="() => clickHandler(index)"
        >
          <!-- eslint-disable vue/no-v-html -->
          <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
          <span
            v-if="item?.highlightedTitle"
            class="text-action-default-low body-1 block flex-1 truncate"
            v-html="item.highlightedTitle"
          />
          <!-- eslint-enable -->
          <span v-else class="block flex-1 truncate">
            {{ item.title }}
          </span>
        </button>
        <RevButtonRounded
          class="absolute right-24 top-4 group-hover:visible lg:invisible"
          :icon="IconArrowUpLeft"
          variant="secondary"
          @mousedown.stop="() => updateInputHandler(item.title)"
        />
      </div>
      <div
        v-if="inputValue"
        class="bg-static-default-min hover:bg-static-default-min-hover"
        tabindex="-1"
      >
        <button
          class="w-full px-24 py-12 text-left"
          @mousedown="() => clickHandler()"
        >
          <span class="body-1-bold flex-1 truncate"> "{{ inputValue }}" </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowUpLeft } from '@ds/icons/IconArrowUpLeft'

import type { Link } from '../../composables/useSearch'

import translations from './Listbox.translations'

const props = withDefaults(
  defineProps<{
    items: Link[]
    name: string
    selectedIndex: number
    title?: string
    withClear?: boolean
    inputValue?: string
  }>(),
  {
    title: undefined,
    withClear: false,
    inputValue: '',
  },
)

const i18n = useI18n()
const listbox = ref<HTMLInputElement | null>(null)

const emit = defineEmits(['clear', 'select', 'update-input'])

function clickHandler(index?: number) {
  emit('select', index, props.name)
}

function cleanHandler() {
  emit('clear', props.name)
}

function updateInputHandler(value: string) {
  emit('update-input', value)
}

watch(
  () => props.selectedIndex,
  (newSelectedIndex) => {
    const item =
      newSelectedIndex === -1
        ? listbox.value?.querySelector('[role = "option"]')
        : listbox.value?.querySelector('[aria-selected = "true"]')

    if (item) {
      item.scrollIntoView({ block: 'center' })
    }
  },
)
</script>
