export const ROUTE_NAMES = {
  DASHBOARD: {
    SELF: 'dashboard',
    FAVORITES: 'favorites',
    MY_ORDERS: {
      SELF: 'orders',
      WITHDRAW: {
        INSURANCES: 'withdraw_insurances',
      },
    },
    MY_PETANQUE_ORDERS: 'petanque_orders',
    MY_SALES: 'my_sales',
    KYC: {
      OTHER: 'dashboard_other',
      PROFILE: 'profile',
      DELETE_ACCOUNT: 'delete_account',
      REFER_FRIEND: 'refer_friend',
    },
  },
}

export const MODAL_NAMES = {
  KYC: {
    PROFILE_PASSWORD_EDIT: 'profile_password_edit',
    PROFILE_INFO_EDIT: 'profile_info_edit',
    RIB_UPLOAD_MODAL: 'RIB_UPLOAD_MODAL',
    RIB_UPLOAD_CONFIRMATION_MODAL: 'RIB_UPLOAD_CONFIRMATION_MODAL',
    UNLINK_SOCIAL_ACCOUNTS_MODAL: 'UNLINK_SOCIAL_ACCOUNTS_MODAL',
  },
}
