import { createError } from '#imports'
import { type Ref, ref } from 'vue'

import type { HttpError } from '@backmarket/http-api'
import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { useFetchContent } from '~/scopes/cms/composables/useFetchPageContent'
import { useFormatContentResponse } from '~/scopes/cms/composables/useFormatContentResponse'
import { usePageParamsFromRoute } from '~/scopes/cms/composables/usePageParamsFromRoute'

import { getStaticHomeData } from '../fallback/getStaticHomeData'

const hasNoError = (
  data: Ref<BasePageContent | null>,
  error: Ref<HttpError | null>,
): data is Ref<BasePageContent> => {
  return !!data.value && !error.value
}
export async function useFetchHomeData({
  useFallbackOnError,
}: {
  useFallbackOnError: boolean
}): Promise<Ref<BasePageContent>> {
  const logger = useLogger()
  const locale = useI18nLocale()
  const formatContentResponse = useFormatContentResponse()

  const pageParams = usePageParamsFromRoute()
  const { data, error } = await useFetchContent()

  if (hasNoError(data, error)) return data

  if (!useFallbackOnError)
    throw createError({
      statusCode: error.value?.statusCode ?? 500,
      statusMessage: error.value?.message ?? '',
      fatal: true,
    })

  logger.error(
    '[CMS] [HP] An error occurred. Showing static home data instead.',
    {
      error: error.value as HttpError,
      info: pageParams,
      owners: ['bot-squad-content-front'],
    },
  )
  const staticHomeData = formatContentResponse(await getStaticHomeData(locale))

  return ref(staticHomeData)
}
