export default {
  startingFrom: {
    id: 'card_starting_from',
    defaultMessage: 'Starting from',
  },
  addToCart: {
    id: 'card_add_to_cart',
    defaultMessage: 'Add to cart',
  },
  tinyAddToCart: {
    id: 'card_tiny_add_to_cart',
    defaultMessage: 'Add',
  },
  productCardWarranty: {
    id: 'pthumbs_warranty_new',
    defaultMessage: 'Warranty: {warranty}',
  },
  gradeDescription: {
    id: 'product_card_condition',
    defaultMessage: 'Condition: {grade}',
  },
  months: {
    id: 'landing_months',
    defaultMessage: 'months',
  },
  imageAlt: {
    id: 'product_card_image_alt',
    defaultMessage: '{label} image',
  },
} as const
