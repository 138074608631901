<template>
  <div class="flex flex-col">
    <div class="text-static-default-hi body-2-bold" data-qa="productCardPrice">
      {{ priceValue }}
      <span v-if="label">
        {{ label }}
      </span>
    </div>

    <div v-if="priceNew" class="text-static-default-low body-2 line-through">
      {{ priceNew }}
      <span>
        {{ i18n(translations.newKeyword) }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './ProductPrice.translations'

const props = defineProps<{
  price: string
  priceNew?: string
  discountedPrice?: string
}>()

const i18n = useI18n()
const experiments = useExperiments()

const isDiscountedPriceExperiment =
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPrice' ||
  experiments['experiment.discountedPriceInProductCard'] ===
    'withDiscountedPriceOnlyAfter'

const isDiscountedPriceExperimentWithBeforeTradeIn =
  experiments['experiment.discountedPriceInProductCard'] ===
  'withDiscountedPrice'

const priceValue = computed(() => {
  if (props.discountedPrice && props.priceNew && isDiscountedPriceExperiment) {
    return props.discountedPrice
  }

  return props.price
})

const label = computed(() => {
  if (props.discountedPrice && props.priceNew && isDiscountedPriceExperiment) {
    return i18n(translations.afterBuyback)
  }
  if (
    !props.discountedPrice &&
    props.priceNew &&
    isDiscountedPriceExperimentWithBeforeTradeIn
  ) {
    return i18n(translations.beforeBuyback)
  }

  return ''
})
</script>
