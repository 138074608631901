import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import { isBrowser } from '@backmarket/utils/env/isBrowser'

import { getDeviceId as getAmplitudeDeviceId } from '../amplitude'
import { COOKIES } from '../constants'

import {
  BRAZE_SAFARI_PUSH_ID,
  SDK_ENDPOINT,
  SERVICE_WORKER_LOCATION,
} from './constants'

export type BrazeInstance = typeof import('@braze/web-sdk')
let instance = <BrazeInstance>{}

function isInstanceAvailable() {
  return Object.keys(instance).length > 0
}

function isInstanceDisabled() {
  return !isInstanceAvailable() || Boolean(instance?.isDisabled())
}

function hasStoredAmplitudeDeviceId() {
  const localStorage = useLocalStorage()
  const amplitudeDeviceId = localStorage.getItem('amplitudeDeviceId')

  return !!amplitudeDeviceId
}

function setAmplitudeDeviceId() {
  const amplitudeDeviceId = getAmplitudeDeviceId()

  if (isInstanceDisabled() || !amplitudeDeviceId) {
    return
  }

  instance
    .getUser()
    ?.setCustomUserAttribute('amplitude_device_id', amplitudeDeviceId)

  const localStorage = useLocalStorage()
  localStorage.setItem('amplitudeDeviceId', amplitudeDeviceId)
}

const setGoogleAdCustomAttributes = ({
  hasUserConsent,
}: {
  hasUserConsent: boolean
}) => {
  if (isInstanceDisabled()) {
    return
  }

  const userHandle = instance.getUser()

  userHandle?.setCustomUserAttribute('$google_ad_user_data', hasUserConsent)
  userHandle?.setCustomUserAttribute(
    '$google_ad_personalization',
    hasUserConsent,
  )
}

export function setUserContext({ crmId }: { crmId?: string }) {
  if (isInstanceDisabled()) {
    return
  }

  if (crmId) {
    instance.changeUser(crmId)
  }

  if (!hasStoredAmplitudeDeviceId()) {
    setAmplitudeDeviceId()
  }
}

export function registerBrazeServiceWorker() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      void navigator.serviceWorker.register(SERVICE_WORKER_LOCATION)
    })
  }
}

export const sendEvent = (
  eventName: string,
  payload: Record<string, unknown>,
) => {
  if (isInstanceDisabled()) {
    return
  }

  instance.logCustomEvent(eventName, payload)
}

type InitBrazeParameters = {
  apiKey: string
  isOptInCookieNeeded: boolean
}

export const initBraze = async ({
  apiKey,
  isOptInCookieNeeded,
}: InitBrazeParameters) => {
  instance = await import('@braze/web-sdk')

  const areNeededCookiesAccepted =
    getBrowserCookie(COOKIES.userExperience) === 'true' &&
    getBrowserCookie(COOKIES.advertising) === 'true'

  const optIn = isOptInCookieNeeded ? areNeededCookiesAccepted : true

  if (!optIn) {
    instance.disableSDK()
  } else {
    instance.enableSDK()
  }

  const initOptions = {
    baseUrl: SDK_ENDPOINT,
    enableLogging: false,
    safariWebsitePushId: BRAZE_SAFARI_PUSH_ID,
    serviceWorkerLocation: SERVICE_WORKER_LOCATION,
  }

  instance.initialize(apiKey, initOptions)

  return instance
}

// called on GDPR update event
export const setOptIn = (optIn: boolean) => {
  if (!isInstanceAvailable()) {
    return
  }

  if (optIn) {
    instance.enableSDK()
    setGoogleAdCustomAttributes({ hasUserConsent: optIn })
  } else {
    setGoogleAdCustomAttributes({ hasUserConsent: optIn })
    instance.requestImmediateDataFlush()
    instance.disableSDK()
  }
}

export function getInstance(): BrazeInstance | null {
  if (!isBrowser() || isInstanceDisabled()) {
    return null
  }

  return instance
}
