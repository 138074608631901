import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const logger = useLogger()

  nuxtApp.hook('vue:error', (error) => {
    if (error instanceof Error) {
      logger.error(error.message, {
        error,
        owners: [],
      })
    }
  })

  nuxtApp.hook('app:error', (error) => {
    logger.error(error.message, {
      error,
      owners: [],
    })
  })
})
