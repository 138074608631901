<template>
  <div class="flex flex-col">
    <BlockTitle :subtitle :title />

    <component
      :is="tag"
      :rel="link?.rel"
      :target="link?.target"
      :to="link?.href"
      @click="sendTrackingData"
    >
      <ImageRenderer
        v-if="image"
        :alt="image?.alt"
        :height="image?.height"
        :height-mobile="image?.heightMobile"
        :image-class="rounded ? 'rounded-lg' : ''"
        :media-max-width="MediaMaxWidth.SM"
        :src="image?.src"
        :src-mobile="image?.srcMobile"
        :width="image?.width"
        :width-mobile="image?.widthMobile"
      />
    </component>

    <RichText
      v-if="caption && hasRichText"
      :class="['mt-32 md:mt-16', $style.caption]"
      :ignore-links
      :light-text="true"
      :rich-text="caption"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { MediaProps } from '@backmarket/http-api/src/api-specs-content/models/media'
import BlockTitle from '@backmarket/nuxt-layer-cms/BlockTitle.vue'
import { MediaMaxWidth } from '@backmarket/nuxt-layer-cms/ImageRenderer.constants'
import ImageRenderer from '@backmarket/nuxt-layer-cms/ImageRenderer.vue'
import RichText from '@backmarket/nuxt-layer-cms/RichText.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButtonBase } from '@ds/components/ButtonBase'

export type MediaBlockProps = MediaProps & ContentBlockProps

const props = withDefaults(defineProps<MediaBlockProps>(), {
  image: undefined,
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()

const hasLink = computed(() => !isEmpty(props.link))
const tag = computed(() => (hasLink.value ? RevButtonBase : 'div'))

const hasRichText = computed(() => !isEmpty(props.caption))

function sendTrackingData() {
  if (hasLink.value) {
    trackClick({
      ...(props.tracking || {}),
      ...{ name: props.image?.alt ?? {} },
    })
  }
}
</script>

<style module>
.caption * {
  @apply body-2;
}
</style>
