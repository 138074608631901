import { DEVICE } from '@backmarket/http-api/src/api-specs-content/models/device'

export const HIDE_ON_DEVICE_CLASSES = {
  [DEVICE.Mobile]: 'hidden md:block',
  [DEVICE.Desktop]: 'block md:hidden',
} as const

export const SHOW_ON_DEVICE_CLASSES = {
  [DEVICE.Mobile]: 'block md:hidden',
  [DEVICE.Desktop]: 'hidden md:block',
} as const

export type VisibilityOnDeviceClasses =
  | typeof HIDE_ON_DEVICE_CLASSES
  | typeof SHOW_ON_DEVICE_CLASSES
