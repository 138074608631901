import { useRuntimeConfig } from '#imports'
import type { RouteRecordRaw } from 'vue-router'

import type { Market } from '@backmarket/http-api'

import { ROUTES } from './route-names'

export default ({ market }: { market: Market }): RouteRecordRaw[] => {
  const {
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_AUTH_LOGIN,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESET_PASSWORD,
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_SET_NEW_PASSWORD,
  } = useRuntimeConfig().public

  const disableClientSideNavigationAuthLogin = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_AUTH_LOGIN as string
  )?.includes(market.countryCode)

  const disableClientSideNavigation = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_RESET_PASSWORD as string
  )?.includes(market.countryCode)

  const disableClientSideNavigationNewPassword = !(
    FF_ENABLED_COUNTRIES_FRONT_APPS_ROUTE_SET_NEW_PASSWORD as string
  )?.includes(market.countryCode)

  return [
    {
      component: () => import('./pages/Login/Login.vue'),
      name: ROUTES.AUTH.LOGIN,
      path: '/auth/login',
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigationAuthLogin,
        layout: 'minimal',
      },
    },
    {
      component: () => import('./pages/ResetPassword/ResetPassword.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD,
      path: '/:locale/password-reset',
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigation,
        layout: false,
      },
    },
    {
      component: () =>
        import('./pages/ResetPasswordConfirm/ResetPasswordConfirm.vue'),
      name: ROUTES.AUTH.RESET_PASSWORD_CONFIRM,
      path: '/:locale/password-reset/confirm',
      meta: {
        disableClientSideNavigation: () => disableClientSideNavigation,
        layout: false,
      },
    },
    {
      component: () => import('./pages/SetNewPassword/SetNewPassword.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD,
      path: '/:locale/password-reset/set-new/:userId/:token',
      meta: {
        disableClientSideNavigation: () =>
          disableClientSideNavigationNewPassword,
      },
    },
    {
      component: () =>
        import('./pages/SetNewPasswordExpired/SetNewPasswordExpired.vue'),
      name: ROUTES.AUTH.SET_NEW_PASSWORD_EXPIRED,
      path: '/:locale/password-reset/expired-reset',
      meta: {
        disableClientSideNavigation: () =>
          disableClientSideNavigationNewPassword,
      },
    },
  ]
}
