import { navigateTo, useLinkResolver } from '#imports'
import { type Ref, computed, ref } from 'vue'

import { type CountryCodeValue, MarketCountryCode } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useLocalStorage } from '@backmarket/utils/composables/useLocalStorage'

import { HOME } from '~/scopes/home/route-names'

export function useCountrySwitchRedirection(
  detectedCountryCode: Ref<string | undefined>,
) {
  const thisMarket = useMarketplace().market

  const { getLink } = useLinkResolver()
  const localStorage = useLocalStorage()
  const isBannerClosed = ref(false)

  const linkToDetectedMarket = computed(() => {
    if (!detectedCountryCode) {
      return null
    }

    return (
      getLink({
        link: { name: HOME },
        countryCode:
          MarketCountryCode[detectedCountryCode.value as CountryCodeValue],
      })?.href ?? ''
    )
  })

  const isRedirectionSuggested = computed(() => {
    const alreadyAsked =
      isBannerClosed.value || localStorage.hasKey('country_switch_clicked_at')

    return (
      !alreadyAsked &&
      detectedCountryCode &&
      detectedCountryCode.value !== thisMarket.countryCode
    )
  })

  const handleClose = () => {
    isBannerClosed.value = true
    localStorage.setItem('country_switch_clicked_at', Date.now().toString())
  }
  const handleRedirection = () => {
    handleClose()
    void navigateTo(linkToDetectedMarket.value, { external: true })
  }

  return {
    isRedirectionSuggested,
    currentMarketCountryCode: thisMarket.countryCode,
    linkToDetectedMarket,
    handleClose,
    handleRedirection,
  }
}
