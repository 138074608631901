import { type MaybeRefOrGetter, computed, ref, toValue } from 'vue'

import type { QuizProps } from '@backmarket/http-api/src/api-specs-content/models/quiz'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import QuizTranslations from '../Quiz.translations'

import { isEndOfQuiz, mostFrequentItemInArray } from './utils'

export function useSimpleQuiz(quiz: MaybeRefOrGetter<QuizProps>) {
  const currentQuestionIndex = ref(0)
  const currentQuestion = computed(() => {
    const { questions } = toValue(quiz)
    const questionsCount = questions.length

    return currentQuestionIndex.value >= questionsCount
      ? questions[0]
      : questions[currentQuestionIndex.value]
  })

  const answeredProfiles = ref<string[]>([])

  function getMostPopularProfile() {
    const mostFrequentProfile = mostFrequentItemInArray(answeredProfiles.value)

    return mostFrequentProfile
  }

  function getMostPopularProfileLink() {
    const mostFrequentProfile = getMostPopularProfile()

    return toValue(quiz).profileMapping[mostFrequentProfile]
  }

  function incrementQuestionIndex() {
    currentQuestionIndex.value += 1
  }

  function storeProfileAnswer(answerIndex: number) {
    const answerProfile = currentQuestion.value.answers[answerIndex].profile
    answeredProfiles.value.push(answerProfile)
  }

  function answerQuestion({ answerIndex }: { answerIndex: number }) {
    storeProfileAnswer(answerIndex)
    incrementQuestionIndex()
  }

  const i18n = useI18n()
  const currentQuestionLabel = computed(() =>
    i18n(QuizTranslations.currentQuestionOutOfLabel, {
      currentQuestionNumber: currentQuestionIndex.value + 1,
      totalQuestions: toValue(quiz).questions.length,
    }),
  )

  function goBackToPreviousQuestion() {
    answeredProfiles.value.pop()
    currentQuestionIndex.value -= 1
  }

  return {
    currentQuestion,
    answerQuestion,
    storeProfileAnswer,
    incrementQuestionIndex,
    currentQuestionLabel,
    isEndOfQuiz: () =>
      isEndOfQuiz({
        quiz: toValue(quiz),
        currentQuestionIndex: currentQuestionIndex.value,
      }),
    getMostPopularProfile,
    getMostPopularProfileLink,
    goBackToPreviousQuestion,
    currentQuestionIndex,
  }
}
