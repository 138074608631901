import { defineNuxtRouteMiddleware } from '#imports'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

// As per nuxt documentation we should not return anything or return undefined if we do not want to alter the redirection.
// https://nuxt.com/docs/guide/directory-structure/middleware
// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware((to) => {
  const logger = useLogger()

  logger.debug(`[Checkout] Attempting to load ${String(to.name)}`, {
    owners: ['bot-squad-checkout-front'],
  })
})
