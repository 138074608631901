import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useRequestURL,
  useRoute,
  useRuntimeConfig,
} from '#imports'

import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

type PaymentRouteMeta = {
  /**
   * When true, users will be redirected to the payment subdomain when entering
   * the route.
   */
  subdomainEnabled: boolean
}

declare module 'vue-router' {
  interface RouteMeta {
    /**
     * Configure payment-specific behavior for the route, such as whether to
     * redirect to the payment subdomain.
     */
    payment?: PaymentRouteMeta
  }
}

const DEFAULT_ROUTE_META: PaymentRouteMeta = {
  subdomainEnabled: false,
}

const WWW_SUBDOMAIN = 'www'
const PAYMENT_PART = 'payment'

function getBackmarketDomainRegExp() {
  return new RegExp(
    [
      '^',
      '(?<prefix>.*?)',
      `(?<payment>\\.?${PAYMENT_PART})?`,
      '\\.backmarket',
      '\\.(?<tld>(co\\.)?[a-z]+)',
      '$',
    ].join(''),
  )
}

export function getHostInfo(url: URL) {
  const match = url.host.match(getBackmarketDomainRegExp())

  // Fallback for unexpected local cases, such as https://localhost, etc.
  if (match === null) {
    return {
      regularHost: url.host,
      paymentHost: url.host,
      isPaymentHost: false,
    }
  }

  const { prefix, payment, tld } = match.groups || {}
  const isPaymentHost = !!payment
  const isWww = isPaymentHost ? prefix === '' : prefix === WWW_SUBDOMAIN

  return {
    regularHost: `${isWww ? WWW_SUBDOMAIN : prefix}.backmarket.${tld}`,
    paymentHost: `${isWww ? PAYMENT_PART : `${prefix}.${PAYMENT_PART}`}.backmarket.${tld}`,
    isPaymentHost,
  }
}

export function routeToURL(route: ReturnType<typeof useRoute>) {
  const url = new URL(useRequestURL().origin)
  url.pathname = route.fullPath

  return url
}

export default defineNuxtRouteMiddleware(
  // eslint-disable-next-line consistent-return
  function paymentSubdomainMiddleware(routeTo) {
    const { countryCode } = useMarketplace().market
    const config = useRuntimeConfig().public.payment
    if (!config.FF_SUBDOMAIN_ENABLED_COUNTRIES.includes(countryCode)) {
      return undefined
    }

    const routeMeta = {
      ...DEFAULT_ROUTE_META,
      ...(routeTo.meta?.payment || {}),
    }
    const url = routeToURL(routeTo)
    const { regularHost, paymentHost, isPaymentHost } = getHostInfo(url)

    // Redirect from regular to payment subdomain
    if (routeMeta.subdomainEnabled && !isPaymentHost) {
      url.host = paymentHost

      return navigateTo(url.href, { external: true })
    }

    // Redirect from payment to regular subdomain
    if (!routeMeta.subdomainEnabled && isPaymentHost) {
      url.host = regularHost

      return navigateTo(url.href, { external: true })
    }
  },
)
