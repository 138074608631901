import { hashObjects } from '@backmarket/utils/object/hashObjects'

export function getAsyncDataKey(
  prefix: string,
  object?: Record<string, unknown>,
): string {
  if (object === undefined) {
    return prefix
  }

  return `${prefix}:${hashObjects(object)}`
}
