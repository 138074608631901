<template>
  <RevButtonBase
    :aria-label="COMPANY_NAME"
    :to="resolveLocalizedRoute({ name: HOME })"
    :tracking="{ name: 'logo', zone: 'header' }"
  >
    <RevIllustration
      :alt="COMPANY_NAME"
      :height="34"
      image-class-names="w-auto h-12 md:h-16 lg:h-20"
      src="/img/header/Logo.svg"
      :width="300"
    />
  </RevButtonBase>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'

import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import { HOME } from '~/scopes/home/route-names'

const runtimeConfig = useRuntimeConfig()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const { COMPANY_NAME } = runtimeConfig.public
</script>
