import { type Component, markRaw, reactive } from 'vue'

import { IconCheckInCircle } from '@backmarket/design-system/icons/IconCheckInCircle'
import { IconCrossInCircle } from '@backmarket/design-system/icons/IconCrossInCircle'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './useTheToast.translations'

interface ToastState {
  content: string
  closeAlternativeText: string
  duration: number
  icon?: object
  isOpened: boolean
  name: string
  title: string
  type: 'error' | 'success'
}

interface ToastParam {
  title?: string
  content?: string
  icon?: Component
  duration?: number
  name?: string
  closeAlternativeText?: string
}

const DEFAULT_TOAST_STATE: ToastState = {
  content: '',
  closeAlternativeText: '',
  duration: 10000,
  isOpened: false,
  name: 'the-toast',
  title: '',
  type: 'success',
}

const toastInfo: ToastState = reactive({ ...DEFAULT_TOAST_STATE })

function closeToast() {
  Object.assign(toastInfo, DEFAULT_TOAST_STATE)
}

function openErrorToast(toastParams?: ToastParam) {
  const i18n = useI18n()

  // those values (error and success) are variant of the RevToast DS component.
  // toDo: export them in DS and import them here
  toastInfo.type = 'error'
  toastInfo.icon = markRaw(IconCrossInCircle)
  toastInfo.title =
    toastParams?.title || i18n(translations.defaultToastErrorTitle)
  toastInfo.content =
    toastParams?.content || i18n(translations.defaultToastErrorContent)
  toastInfo.closeAlternativeText = i18n(translations.closeToast)
  toastInfo.isOpened = true
}

function openSuccessToast(toastParams?: ToastParam) {
  const i18n = useI18n()

  toastInfo.type = 'success'
  toastInfo.icon = toastParams?.icon
    ? markRaw(toastParams.icon)
    : markRaw(IconCheckInCircle)
  toastInfo.title =
    toastParams?.title || i18n(translations.defaultToastSuccessTitle)
  toastInfo.content =
    toastParams?.content || i18n(translations.defaultToastSuccessContent)
  toastInfo.closeAlternativeText =
    toastParams?.closeAlternativeText || i18n(translations.closeToast)
  toastInfo.duration = toastParams?.duration || DEFAULT_TOAST_STATE.duration
  toastInfo.name = toastParams?.name || DEFAULT_TOAST_STATE.name
  toastInfo.isOpened = true
}

export function useTheToast() {
  return {
    openSuccessToast,
    openErrorToast,
    toastInfo,
    closeToast,
    TOAST_CONTENT_TELEPORT: '#toast-content',
  }
}
