<template>
  <div>
    <RevButtonBase
      v-if="showPlaceHolder"
      class="relative h-[184px] w-full md:h-[415px]"
      data-test="youtube-facade"
      @click="showPlaceHolder = false"
    >
      <ImageRenderer
        :alt="title"
        :height="415"
        :src="`https://img.youtube.com/vi/${youtubeId}/sddefault.jpg`"
        :src-mobile="`https://img.youtube.com/vi/${youtubeId}/sddefault.jpg`"
        :width="736"
      />

      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <RevIllustration
          alt=""
          :height="48"
          src="/img/cms/YoutubePlay.svg"
          :width="68"
        />
      </div>
    </RevButtonBase>

    <iframe
      v-else
      allow="autoplay; encrypted-media"
      allowfullscreen
      class="aspect-video w-full"
      data-test="youtube-iframe"
      frameborder="0"
      loading="lazy"
      :src="`https://www.youtube.com/embed/${youtubeId}?autoplay=1`"
      :title
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import ImageRenderer from '../ImageRenderer/ImageRenderer.vue'

defineProps<{ youtubeId: string; title?: string }>()

const showPlaceHolder = ref<boolean>(true)
</script>
