import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { canUseLocalStorage } from '@backmarket/utils/composables/useLocalStorage'
import { isBrowser } from '@backmarket/utils/env/isBrowser'

declare global {
  interface Window {
    ebRecoIds?: string[]
  }
}

export const getUserRecommendationId = () => {
  if (!canUseLocalStorage()) {
    return null
  }

  return localStorage?.getItem('attraqtsessionid')
}

export const setRecommendationDebugExtension = (id: string | null = null) => {
  if (!isBrowser()) {
    return
  }
  if (!id) {
    return
  }
  window.ebRecoIds = [...new Set(window.ebRecoIds).add(id)]
}

export const saveProductInfos = ({
  id,
  price,
  source,
  reviewRating,
}: {
  id: string
  price: string
  source: string
  reviewRating?: string
}) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(
      id,
      JSON.stringify({ price, source, reviewRating }),
    )
  }
}

export const getProductInfos = (id: string) => {
  const logger = useLogger()
  try {
    if (window.sessionStorage) {
      const item = window.sessionStorage.getItem(id)

      return item ? JSON.parse(item) : {}
    }
  } catch (error) {
    logger.info('[S&R][Error][ProductPage] Error accessing sessionStorage', {
      error: error instanceof Error ? error : undefined,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }

  return {}
}

export const getPercent = (
  price: string | number,
  reference: string | number,
) => {
  const number = typeof price === 'string' ? parseInt(price, 10) : price
  const referencedNumber =
    typeof reference === 'string' ? parseInt(reference, 10) : reference

  return number < referencedNumber
    ? Math.ceil(((number - referencedNumber) / referencedNumber) * 100)
    : null
}

export function logDesync({
  id,
  isOutOfStock,
  price,
}: {
  id: string
  isOutOfStock?: boolean
  price?: string
}) {
  const {
    price: storedPrice,
    source,
  }: {
    price?: string
    reviewRating?: string
    source?: string
  } = getProductInfos(id)

  const logger = useLogger()

  if (source === undefined || storedPrice === undefined) {
    return
  }

  const numericPrice = price ? parseFloat(price) : NaN
  const numericStoredPrice = parseFloat(storedPrice)
  if (!Number.isNaN(numericPrice) && numericPrice !== numericStoredPrice) {
    logger.info('[S&R][Error][ProductPage] Price desync detected', {
      id,
      cardPrice: storedPrice,
      productPrice: price,
      source,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }

  if (isOutOfStock) {
    logger.info('[S&R][Error][ProductPage] Stock desync detected', {
      id,
      source,
      owners: ['bot-squad-search-and-recommendation-front'],
    })
  }
}
