export type LiveIdentityCheckConsent =
  | LiveIdentityCheckConsentAwaited
  | LiveIdentityCheckConsentGranted
  | LiveIdentityCheckConsentRefused
  | LiveIdentityCheckConsentExpired

export type LiveIdentityCheckConsentUpdated =
  | Omit<LiveIdentityCheckConsentGranted, 'expiredAt'>
  | Omit<LiveIdentityCheckConsentRefused, 'expiredAt'>

export type LiveIdentityCheckConsentStatus = 'GRANT_CONSENT' | 'REFUSE_CONSENT'

export type LiveIdentityCheckConsentAwaited = {
  identityCheckLink: null
  consentGrantedAt: null
  consentRefusedAt: null
  expiredAt: null
}

export type LiveIdentityCheckConsentGranted = {
  identityCheckLink: string
  consentGrantedAt: string
  consentRefusedAt: null
  expiredAt: null
}
export type LiveIdentityCheckConsentRefused = {
  identityCheckLink: null
  consentGrantedAt: null
  consentRefusedAt: string
  expiredAt: null
}
export type LiveIdentityCheckConsentExpired = (
  | LiveIdentityCheckConsentUpdated
  | Omit<LiveIdentityCheckConsentAwaited, 'expiredAt'>
) & {
  expiredAt: string
}

export type LiveIdentityCheckStatus =
  | 'awaited'
  | 'expired'
  | 'granted'
  | 'refused'
  | 'error'

export function isLiveIdentityCheckConsentExpired(
  liveIdentityCheckConsent: LiveIdentityCheckConsent,
): liveIdentityCheckConsent is LiveIdentityCheckConsentExpired {
  return !!liveIdentityCheckConsent.expiredAt
}

export function isLiveIdentityCheckConsentGranted(
  liveIdentityCheckConsent:
    | LiveIdentityCheckConsent
    | LiveIdentityCheckConsentUpdated,
): liveIdentityCheckConsent is LiveIdentityCheckConsentGranted {
  return !!(
    liveIdentityCheckConsent.consentGrantedAt &&
    liveIdentityCheckConsent.identityCheckLink
  )
}

export function isLiveIdentityCheckConsentRefused(
  liveIdentityCheckConsent:
    | LiveIdentityCheckConsent
    | LiveIdentityCheckConsentUpdated,
): liveIdentityCheckConsent is LiveIdentityCheckConsentRefused {
  return !!liveIdentityCheckConsent.consentRefusedAt
}

export function isLiveIdentityCheckConsentAwaited(
  liveIdentityCheckConsent: LiveIdentityCheckConsent,
): liveIdentityCheckConsent is LiveIdentityCheckConsentAwaited {
  return (
    !isLiveIdentityCheckConsentRefused(liveIdentityCheckConsent) &&
    !isLiveIdentityCheckConsentGranted(liveIdentityCheckConsent) &&
    !isLiveIdentityCheckConsentExpired(liveIdentityCheckConsent)
  )
}
