import { createHttpEndpoint } from '@backmarket/http-api'
import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'

import { useFetchFormattedPageContent } from '~/scopes/cms/composables/useFetchFormattedPageContent'

const CONTENT_MANAGEMENT_SERVICE_LEGAL_PAGE_PATH = '/content/page/legal-page'
const LEGAL_PAGE_URL_IDENTIFIER = 'live-identity-check-data-process-consent'

export function useGetContentLegalPageLiveIdentityCheckDataProcessConsent() {
  const endpoint = createHttpEndpoint<BasePageContentAPI>({
    method: 'GET',
    operationId: 'fetchContentFromContentService',
    path: `${CONTENT_MANAGEMENT_SERVICE_LEGAL_PAGE_PATH}/${LEGAL_PAGE_URL_IDENTIFIER}`,
  })

  const { data } = useFetchFormattedPageContent(endpoint, {})

  return { content: data }
}
