import { type MaybeRefOrGetter, toRaw, toValue } from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useAuthStore } from '@backmarket/nuxt-layer-oauth/useAuthStore'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { get } from '@backmarket/utils/object/get'
import {
  createSharedComposable,
  promiseTimeout,
  useScriptTag,
} from '@vueuse/core'

import type {
  GooglePay,
  GooglePayIsReadyToPayRequest,
  GooglePayPaymentDataRequest,
  GooglePayPaymentsClient,
  GooglePayTransactionInfo,
} from '../../../types/google-pay'
import { PaymentError } from '../../form-common'
import { isValidGooglePayPaymentMethodConfig } from '../../form-common/helpers/isValidConfig'
import { PaymentMethodMisconfiguredError } from '../../form-common/types/PaymentMethodMisconfiguredError'

type GoogleEventKey = 'user_could_have_gpay'

const GOOGLE_PAY_LIBRARY_URL = 'https://pay.google.com/gp/p/js/pay.js'
const GOOGLE_PAY_READY_TIMEOUT = 800

const BASE_REQUEST = {
  apiVersion: 2,
  apiVersionMinor: 0,
}

class GooglePayReadyTimeoutError extends Error {
  constructor() {
    super('Google Pay isReady timeout')
  }
}

function useCustomLogger(
  logger: ReturnType<typeof useLogger>,
  method: MaybeRefOrGetter<PaymentMethod | null>,
) {
  const { userId: clientId } = useAuthStore()

  function log(key: GoogleEventKey, data?: Record<string, unknown>) {
    const payload = {
      clientId,
      type: key.toUpperCase(),
      payment: {
        method: toValue(method),
        event: { key },
        ...data,
      },
    }

    logger.info(`[PAYMENT] [GooglePay] ${key}`, {
      ...payload,
      owners: ['bot-squad-payin-front'],
    })
  }

  return log
}

function useGooglePayScript(): () => Promise<GooglePay> {
  const { load } = useScriptTag(GOOGLE_PAY_LIBRARY_URL)

  return async () => {
    await load(true)

    return window.google
  }
}

export const useGooglePay = createSharedComposable(
  (method: MaybeRefOrGetter<PaymentMethod | null>) => {
    const logger = useLogger()
    const log = useCustomLogger(logger, method)
    const googlePayScript = useGooglePayScript()

    function rawConfig() {
      const raw = toRaw(toValue(method))?.config

      if (isValidGooglePayPaymentMethodConfig(raw)) {
        return raw
      }

      throw new PaymentMethodMisconfiguredError(
        'Invalid Google Pay configuration',
      )
    }

    let paymentsClient: GooglePayPaymentsClient
    async function getPaymentsClient() {
      if (!paymentsClient) {
        const { environment } = rawConfig()
        const library = await googlePayScript()

        paymentsClient = new library.payments.api.PaymentsClient({
          environment,
        })
      }

      return paymentsClient
    }

    function getIsReadyToPayRequest(): GooglePayIsReadyToPayRequest {
      const { type, parameters } = rawConfig()

      return {
        ...BASE_REQUEST,
        allowedPaymentMethods: [{ type, parameters }],
        existingPaymentMethodRequired: true,
      }
    }

    function getPaymentDataRequest(
      transactionInfo: GooglePayTransactionInfo,
    ): GooglePayPaymentDataRequest {
      const { type, parameters, tokenizationSpecification, merchantInfo } =
        rawConfig()

      return {
        ...BASE_REQUEST,
        transactionInfo,
        merchantInfo,
        allowedPaymentMethods: [
          { type, parameters, tokenizationSpecification },
        ],
      }
    }

    async function isReadyToPay(): Promise<boolean> {
      const client = await getPaymentsClient()

      const request = getIsReadyToPayRequest()
      const { result, paymentMethodPresent } =
        await client.isReadyToPay(request)

      if (!result) {
        throw new PaymentError('Google Pay is not ready to pay', {
          type: '/errors/payment/google-pay-not-ready',
        })
      }

      return paymentMethodPresent === true
    }

    /**
     * Checks if the user can pay with Google Pay
     *
     * Return true if the user has a payment method available, false otherwise
     *
     * Throws a PaymentError if the user can't pay with Google Pay
     */
    async function isReadyWithAPaymentMethodPresent(useTimeout = true) {
      const promises: Promise<boolean>[] = [isReadyToPay()]

      try {
        if (useTimeout) {
          promises.push(
            promiseTimeout(GOOGLE_PAY_READY_TIMEOUT).then(() => {
              throw new GooglePayReadyTimeoutError()
            }),
          )
        }

        return await Promise.race(promises)
      } catch (error) {
        if (error instanceof GooglePayReadyTimeoutError) {
          // Log if the user is able to pay with Google Pay
          promises[0]
            .then((canPay) => {
              if (canPay) {
                log('user_could_have_gpay')
              }
            })
            .catch(() => {
              // ignore error
            })

          return false
        }

        // Re-throw other error (eg: /errors/payment/google-pay-not-ready)
        throw error
      }
    }

    /**
     * Collects browser info for 3DS
     *
     * Required by Aduen
     * @see https://docs.adyen.com/api-explorer/Checkout/latest/post/payments#request-browserInfo
     *
     * This code is a temporary solution until we implement the GooglePay Adyen component copied from :
     * @see https://github.com/Adyen/adyen-web/blob/0ebf396b6416cac0b195fa1516d6a023d3f92728/packages/lib/src/utils/browserInfo.ts
     *
     * TODO: Implement GooglePay Adyen component instead of using the GooglePay API
     * @see https://docs.adyen.com/payment-methods/google-pay/web-component
     */
    function collectBrowserInfo() {
      const colorDepth = get(window, 'screen.colorDepth') || ''
      const javaEnabled = get(window, 'navigator.javaEnabled')
        ? window.navigator.javaEnabled()
        : false
      const screenHeight = get(window, 'screen.height') || ''
      const screenWidth = get(window, 'screen.width') || ''
      const userAgent = get(window, 'navigator.userAgent') || ''

      const language = get(window, 'navigator.language') || 'en'
      const d = new Date()
      const timeZoneOffset = d.getTimezoneOffset()

      return {
        acceptHeader: '*/*',
        colorDepth,
        language,
        javaEnabled,
        screenHeight,
        screenWidth,
        userAgent,
        timeZoneOffset,
      }
    }

    return {
      isReadyWithAPaymentMethodPresent,
      getPaymentsClient,
      getIsReadyToPayRequest,
      getPaymentDataRequest,
      collectBrowserInfo,
    }
  },
)

export type UseGooglePay = ReturnType<typeof useGooglePay>
