<template>
  <CountrySwitchRedirectionBanner
    v-if="detectedCountryCode && linkToDetectedMarket"
    ref="bannerRef"
    :currentCountryCode="currentMarketCountryCode"
    data-test="country-switch-redirection-banner"
    :detectedCountryCode
    :handleClose
    :handleRedirection
    :isRedirectionSuggested
    :linkToDetectedMarket
  />
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref, toRef } from 'vue'

import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useIntersectionObserver } from '@vueuse/core'

import { useCountrySwitchRedirection } from '~/scopes/navigation/composables/useCountrySwitchRedirection'

import CountrySwitchRedirectionBanner from './CountrySwitchRedirectionBanner.vue'

const userStore = useUserStore()
const detectedCountryCode = computed(() => userStore.user.detectedCountryCode)
const {
  isRedirectionSuggested,
  handleClose,
  handleRedirection,
  currentMarketCountryCode,
  linkToDetectedMarket,
} = useCountrySwitchRedirection(toRef(detectedCountryCode))
const bannerRef = ref<HTMLElement | null>()

const { trackCountrySwitchBannerImpression } = useTracking()

function trackImpression() {
  trackCountrySwitchBannerImpression({
    name: 'prompt_country_switch',
    zone: 'banner_country_switch',
  })
}

const { stop } = useIntersectionObserver(bannerRef, ([{ isIntersecting }]) => {
  if (isIntersecting) {
    trackImpression()
  }
})

onUnmounted(() => {
  stop()
})
</script>
