<template>
  <div class="mood-tangaroa flex grow justify-center break-words lg:w-[1120px]">
    <div
      class="bg-surface-default-mid rounded-lg relative z-[1] flex grow flex-col p-32"
    >
      <!-- Keep it invisible to properly size the loading component just like the Quiz -->
      <div
        :class="[
          'flex grow flex-col gap-32 lg:flex-row',
          isFetchingQuizResults ? 'invisible' : '',
        ]"
      >
        <div
          class="text-static-default-mid flex flex-col gap-16 lg:max-w-[420px] lg:grow"
        >
          <div class="text-static-default-hi punchline">
            {{ title }}
          </div>
          <div class="body-1 flex items-start">
            <UnstyledRichText :rich-text="description" />
          </div>
        </div>
        <div
          class="flex grow flex-col gap-16"
          :data-current-question-index="currentQuestionIndex"
        >
          <div
            class="text-static-default-mid flex flex-col items-start gap-8 lg:flex-row-reverse lg:items-center lg:justify-between"
          >
            <div class="flex h-24 items-center gap-4">
              <button
                v-if="currentQuestionIndex > 0"
                class="cursor-pointer bg-transparent"
                type="button"
                @click.prevent="goBackToPreviousQuestion"
              >
                <IconArrowLeft class="fill-current" />
              </button>
              <div class="body-1 whitespace-nowrap">
                {{ currentQuestionLabel }}
              </div>
            </div>
            <div class="heading-2">
              {{ currentQuestion.question }}
            </div>
          </div>
          <div class="mood-main">
            <ol class="body-1 flex list-none flex-col gap-8">
              <li
                v-for="(answer, answerIndex) in currentQuestion.answers"
                :key="answer.id"
              >
                <div
                  class="bg-static-default-low border-action-default-low shadow-short rounded-sm"
                >
                  <RevRadio
                    :id="answer.id"
                    v-model="currentQuestionAnswer"
                    :disabled="
                      animatingBeforeNextQuestion && answerNotSelected(answer)
                    "
                    :value="answer.profile"
                    variant="full"
                    @change="
                      () =>
                        answerQuestionWithTracking({
                          answerIndex: answerIndex,
                          questionIndex: currentQuestionIndex,
                        })
                    "
                  >
                    <template #label>
                      <div class="body-1">
                        {{ answer.text }}
                      </div>
                    </template>
                  </RevRadio>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <QuizLoading v-if="isFetchingQuizResults" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  Answer,
  QuizProps,
} from '@backmarket/http-api/src/api-specs-content/models/quiz'
import UnstyledRichText from '@backmarket/nuxt-layer-cms/UnstyledRichText.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { RevRadio } from '@ds/components/Radio'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import QuizLoading from './QuizLoading.vue'
import { useQuizTracking } from './composables/useQuizTracking'
import { useQuizWithTransitions } from './composables/useQuizWithTransitions'

const quiz = defineProps<ContentBlockProps & QuizProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const {
  isFetchingQuizResults,
  goBackToPreviousQuestion,
  answerQuestion,
  currentQuestionAnswer,
  currentQuestion,
  currentQuestionLabel,
  animatingBeforeNextQuestion,
  currentQuestionIndex,
} = useQuizWithTransitions(() => quiz)

const { trackAnswerClick } = useQuizTracking(() => quiz)

function answerQuestionWithTracking({
  questionIndex,
  answerIndex,
}: {
  questionIndex: number
  answerIndex: number
}) {
  trackAnswerClick({ questionIndex, answerIndex })
  answerQuestion({ answerIndex })
}

const answerNotSelected = (answer: Answer) =>
  answer.profile !== currentQuestionAnswer.value
</script>
