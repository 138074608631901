import { useRequestHeaders, useRuntimeConfig } from '#imports'

import {
  type DefaultHttpRequestBody,
  type HttpEndpoint,
  HttpHeader,
  type HttpRequestOptions,
  createRequestOptionsWithLogging,
} from '@backmarket/http-api'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { createIdentificationUniqueId } from '@backmarket/nuxt-module-identification/createIdentificationUniqueId'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { useTrace } from '@backmarket/nuxt-module-instrumentation/useTrace'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

import { useAppHeaders } from '../composables/useAppHeaders'
import { useHttpStore } from '../composables/useHttpStore'
import { BOT_NEED_CHALLENGE_URL, ERRORS } from '../constants'
import type { HttpFetchRequestOptions } from '../types/HttpFetchRequestOptions'

import { getHttpBaseUrl } from './getHttpBaseUrl'

function getHttpDefaultHeaders() {
  const { market } = useMarketplace()
  const visitorId = useVisitorId()
  const locale = useI18nLocale()
  const httpStore = useHttpStore()

  const { version, platform } = useAppHeaders()

  const headers: Record<string, string> = {
    [HttpHeader.MARKET_COUNTRY_CODE]: market.countryCode,
    [HttpHeader.MARKET_MARKETPLACE]: market.marketplace,
    [HttpHeader.IDENTIFICATION_VISITOR_ID]: visitorId,
    // TODO [PAYIN-2781] Add `x-visit-id` to the allowed CORS headers on back-end side
    // [HttpHeader.IDENTIFICATION_VISIT_ID]: visitId,
    [HttpHeader.IDENTIFICATION_REQUEST_ID]: createIdentificationUniqueId(),
    [HttpHeader.ACCEPT_LANGUAGE]: toRfc4646Locale(locale),
    // @TODO - Since we do not use axios anymore, check if brotli is still breaking on badoom responses
    [HttpHeader.ENCODING]: 'gzip, deflate',
    [HttpHeader.APP_PLATFORM]: platform,
    [HttpHeader.APP_VERSION]: version,
  }

  if (httpStore.csrf) {
    headers[HttpHeader.CSRF_TOKEN] = httpStore.csrf
  }

  return headers
}

export function $httpFetchRaw<T = unknown, B = DefaultHttpRequestBody>(
  endpoint: HttpEndpoint<T, B>,
  options: HttpFetchRequestOptions<T, B> = {},
): Promise<T> {
  const config = useRuntimeConfig().public.http
  const logger = useLogger()

  // Retrieve the HTTP headers when processing a request server-side. Those
  // headers will only be available on the server, and will never leak
  // client-side. As stated in the Nuxt documentation, calling this
  // helper in the browser simply returns an empty object.
  const requestHeaders = useRequestHeaders([
    HttpHeader.COOKIE,
    HttpHeader.VERIFIED_BOT,
    HttpHeader.NGINX_IP,
    HttpHeader.USER_AGENT,
    HttpHeader.GEOIP_CF_COUNTRY,
    HttpHeader.GEOIP_CF_CITY,
    HttpHeader.GEOIP_CF_LATITUDE,
    HttpHeader.GEOIP_CF_LONGITUDE,
  ])

  const headers: Record<string, string> = {
    ...options.headers,
    ...requestHeaders,
  }

  const traceOptions: Parameters<typeof useTrace>[1] = {
    tags: {
      // We allow endpoint.settings to be undefined, so it's easier to mock in testing.
      'resource.name': `${endpoint.settings?.method} ${endpoint.settings?.path}`,
      'http.method': `${endpoint.settings?.method}`,
      'http.url': `${endpoint.settings?.path}`,
    },
  }

  return useTrace('fetch', traceOptions, (span, tracer) => {
    if (tracer && span) {
      // Mutate the `headers` variable to inject additional trace-related
      // headers like `x-datadog-trace-id` or `x-datadog-parent-id`.
      tracer.inject(span, 'http_headers', headers)
    }

    const requestOptions: HttpRequestOptions<T, B> = {
      ...options,
      headers,
      defaultBaseURL: getHttpBaseUrl(),
      onEvent: async (event, context) => {
        options.onEvent?.(event, context)
        const { response, error } = context

        // Checking the response status against undefined ensures that we
        // accept `0` as valid status codes even if it should not happen.
        if (span && response?.status !== undefined) {
          span.setTag('http.status_code', response.status)

          if (response.status >= 500) {
            span.setTag('error', true)
            span.setTag('sampling.priority', 1)
          }
        }

        // This is the "legacy" way to handle BOPIP bot detection.
        // @TODO: implement new BOPIP bot detection - https://backmarket.atlassian.net/browse/FRONT-1195
        if (error?.status === 403 && error.type === ERRORS.BOT_NEED_CHALLENGE) {
          if (process.client) {
            window.location.assign(BOT_NEED_CHALLENGE_URL)
          } else {
            logger.error(
              'BOT_NEED_CHALLENGE error received during server side rendering',
              {
                endpointSettings: context.endpointSettings,
                owners: context.endpointSettings.owners || [
                  'bot-squad-code-foundations-front',
                ],
              },
            )
          }
        }
      },
    }

    return endpoint(
      createRequestOptionsWithLogging<T, B>(
        requestOptions,
        config.isLoggerEnabled ? logger.info : undefined,
      ),
    )
  })
}

/**
 * Call endpoints from @backmarket/http-api with default values:
 * - the default base URL
 * - some default headers
 *
 * @param endpoint The {@link HttpEndpoint endpoint} to call. See `http-api`
 * package for all possible endpoints, and/or how to declare a new endpoint.
 * @param options Additional {@link HttpFetchRequestOptions options}
 *
 * @example
 * import { paymentAPI } from '@backmarket/http-api'
 *
 * const data = await $httpFetch(paymentAPI.getMethods, {
 *   query: { country_code: 'US' },
 * })
 */

export function $httpFetch<T = unknown, B = DefaultHttpRequestBody>(
  endpoint: HttpEndpoint<T, B>,
  options: HttpFetchRequestOptions<T, B> = {},
): Promise<T> {
  const headers = {
    ...getHttpDefaultHeaders(),
    ...options.headers,
  }

  return $httpFetchRaw<T, B>(endpoint, {
    ...options,
    headers,
  })
}
