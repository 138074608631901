export const HEADERS = {
  ACCEPT_LANGUAGE: 'accept-language',
  COOKIE: 'cookie',
  CSRF_TOKEN: 'x-csrftoken',
  ENCODING: 'accept-encoding',
  GEOIP_CF_CITY: 'cf-ipcity',
  GEOIP_CF_COUNTRY: 'cf-ipcountry',
  GEOIP_CF_LATITUDE: 'cf-iplatitude',
  GEOIP_CF_LONGITUDE: 'cf-iplongitude',
  HOST: 'host',
  IDENTIFICATION_REQUEST_ID: 'x-request-id',
  IDENTIFICATION_VISITOR_ID: 'x-visitor-id',
  MARKET_COUNTRY_CODE: 'x-country',
  MARKET_MARKETPLACE: 'x-marketplace',
  NGINX_IP: 'x-forwarded-for',
  USER_AGENT: 'user-agent',
  VERIFIED_BOT: 'x-bm-bopip-verified-bot',
  APP_PLATFORM: 'app-platform',
} as const
