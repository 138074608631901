<template>
  <div class="contents" data-test="lazy-load-wrapper">
    <div ref="observerTarget" class="h-[1px]" />

    <div
      v-if="isLoading"
      class="my-5 text-center"
      data-test="lazy-load-loading-indicator"
    >
      <RevSpinner
        :alternative-text="i18n(translations.loading)"
        data-test="lazy-load-spinner"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeUnmount, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { RevSpinner } from '@ds/components/Spinner'
import { useIntersectionObserver } from '@vueuse/core'

import { LOAD_MORE_DEBOUNCE_DELAY } from './LoadMore.constants'
import translations from './LoadMore.translations'

interface Props {
  isLoading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
})

const emit = defineEmits(['load-more'])

const observerTarget = ref<null | HTMLElement>(null)
const target = ref<IntersectionObserverEntry>()

const i18n = useI18n()

const handleIntersection = (entries: IntersectionObserverEntry[]) => {
  target.value = entries[0]

  if (!props.isLoading && target.value.isIntersecting) {
    emit('load-more')
  }
}

const debounceHandleIntersection = useDebounceFn(
  function debouncedIsLoadingWatch(isLoading) {
    if (isLoading === false && target.value?.isIntersecting) {
      emit('load-more')
    }
  },
  LOAD_MORE_DEBOUNCE_DELAY,
)

const { stop } = useIntersectionObserver(observerTarget, handleIntersection)

onBeforeUnmount(() => {
  stop()
})

watch(
  () => props.isLoading,
  () => {
    debounceHandleIntersection(props.isLoading)
  },
)
</script>
