<template>
  <div
    class="bg-surface-default-mid fixed inset-0 z-10 grid items-center md:absolute"
  >
    <RevLoadingScreen
      data-qa="loading-screen"
      :text="i18n(translations.loading)"
    />
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { onMounted } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevLoadingScreen } from '@ds/components/LoadingScreen'

import { ROUTES } from '~/scopes/auth/route-names'

import { CHECKOUT } from '../../routes-names'

import translations from './Authentication.translations'

const i18n = useI18n()
const logger = useLogger()
const router = useRouter()

onMounted(() => {
  const { href } = router.resolve({ name: CHECKOUT.POST_AUTHENTICATION })

  logger.debug('[Checkout] Client going to authentication', {
    owners: ['bot-squad-checkout-front'],
  })

  router.push({
    name: ROUTES.AUTH.LOGIN,
    query: {
      next: href,
      bm_journey: 'checkout',
    },
  })
})
</script>
