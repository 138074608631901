<template>
  <template v-if="image">
    <RevButtonBase
      v-if="link"
      v-bind="link"
      class="focus-visible-outline-inset-hi block overflow-hidden"
      :tracking
    >
      <ImageRenderer v-bind="image" :image-class />
    </RevButtonBase>

    <ImageRenderer v-else v-bind="image" :image-class />
  </template>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { ModularCardImageProps } from '@backmarket/http-api/src/api-specs-content/models/modular-card'
import ImageRenderer from '@backmarket/nuxt-layer-cms/ImageRenderer.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'

const props = defineProps<ModularCardImageProps & ContentBlockProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const imageClass = computed(() => (props.rounded ? tw`rounded-lg` : ''))
</script>
