<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <p>
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Placeat
      accusantium sunt vel veritatis pariatur necessitatibus blanditiis aliquid
      voluptas saepe esse voluptate voluptatum voluptatem, minus laborum
      perspiciatis cupiditate facere fuga! Sed.
    </p>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel perspiciatis
      magni unde fugit earum sequi, reiciendis at est illo minima architecto
      dolore blanditiis quos debitis aspernatur quibusdam voluptatem accusantium
      dicta.
    </p>
  </div>
</template>

<script setup lang="ts"></script>
