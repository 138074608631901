import { MarketCountryCode } from '@backmarket/http-api'

export const STORE_COUNTRIES = [
  MarketCountryCode.AT,
  MarketCountryCode.BE,
  MarketCountryCode.DE,
  MarketCountryCode.ES,
  MarketCountryCode.FI,
  MarketCountryCode.FR,
  MarketCountryCode.GR,
  MarketCountryCode.IT,
  MarketCountryCode.NL,
  MarketCountryCode.GB,
  MarketCountryCode.IE,
  MarketCountryCode.US,
  MarketCountryCode.PT,
  MarketCountryCode.GR,
  MarketCountryCode.SK,
]
