import {
  DEVICES,
  type Device,
} from '@backmarket/http-api/src/api-specs-content/models/device'
import { isEqual } from '@backmarket/utils/object/isEqual'

import type { VisibilityOnDeviceClasses } from '../../constants/device-visibility-classes.constants'

export const isHiddenOnAllDevices = ({
  devices = [],
}: {
  devices: Device[]
}) => {
  return isEqual(devices, DEVICES)
}

export function getClassesByHiddenDeviceType({
  hiddenDevices,
  classes,
}: {
  hiddenDevices: Device[]
  classes: VisibilityOnDeviceClasses
}) {
  const isHiddenOnExactlyOneDeviceType = hiddenDevices.length === 1

  // the only use case for visibility classes
  // is when the element is hidden on exactly one device type
  if (!isHiddenOnExactlyOneDeviceType) return ''

  const hiddenOn = hiddenDevices[0]
  const visibilityClasses = classes[hiddenOn]

  return visibilityClasses
}
