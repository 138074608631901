export default {
  currentQuestionOutOfLabel: {
    id: 'quiz_current_question_out_of_label',
    defaultMessage: 'Question {currentQuestionNumber} of {totalQuestions}',
  },
  loadingMessage: {
    id: 'quiz_loading_message',
    defaultMessage: "One sec ... we're getting your results",
  },
} as const
