<template>
  <ProductCardCarousel
    v-if="layout === 'carousel'"
    :cta
    :products="productsWithDiscountedPrice"
    :subtitle
    :title
    :tracking="{ ...tracking, provider, list }"
    v-on="addToCartListener ? { addToCart: handleAddToCart } : {}"
  />
  <TitleCtaContainer v-if="layout === 'grid'" :cta :subtitle :title>
    <ProductCardGrid
      :block-position
      :products="productsWithDiscountedPrice"
      :tracking="{ ...tracking, provider, list }"
      v-on="addToCartListener ? { addToCart: handleAddToCart } : {}"
    />
  </TitleCtaContainer>
</template>

<script lang="ts" setup>
import { getCurrentInstance, toRef } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import { useDiscountedPrice } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useDiscountedPrice'

import {
  type ExtraTrackingData,
  useListTrackingData,
} from '../../composables/useProductTracking'
import type { Products } from '../../models/product'
import type { ProductCardProps } from '../ProductCard/ProductCard.vue'
import TitleCtaContainer from '../TitleCtaContainer.vue'

import ProductCardCarousel from './ProductCardCarousel.vue'
import ProductCardGrid from './ProductCardGrid.vue'

const props = withDefaults(
  defineProps<{
    layout: 'grid' | 'carousel'
    products: Products
    provider: 'algolia'
    blockPosition?: number
    tracking?: ExtraTrackingData
    title?: string
    subtitle?: string
    cta?: CTALink
  }>(),
  {
    tracking: () => ({}),
    blockPosition: undefined,
    title: undefined,
    subtitle: undefined,
    cta: undefined,
  },
)

const emit = defineEmits<{
  (e: 'add-to-cart', data: { status: string; product: ProductCardProps }): void
}>()

const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

const list = useListTrackingData({ blockPosition: props.blockPosition })
function handleAddToCart(product: ProductCardProps) {
  return ({ status }: { status: string }) => {
    emit('add-to-cart', { status, product })
  }
}
const { products: productsWithDiscountedPrice } = useDiscountedPrice(
  toRef(props, 'products'),
)
</script>
