<template>
  <div class="bg-surface-default-low px-40 pb-40 pt-24">
    <nav class="mx-auto flex max-w-[1280px] flex-col gap-16">
      <aside class="flex gap-8">
        <p class="text-static-default-mid body-2 flex-1">
          {{ i18n(translations.categories) }}
        </p>
        <RevLink
          class="body-2-link"
          :to="universeURL"
          :tracking="{
            zone: 'mega menu',
            name: universeTitle,
            value: 'view_all',
          }"
          @click="hideMenu"
          >{{ i18n(translations.see_all) }}</RevLink
        >
      </aside>
      <ul
        :aria-label="universeTitle"
        class="grid gap-32 md:grid-cols-3 lg:grid-cols-5"
      >
        <li v-for="item in items" :key="item.id" class="max-w-256">
          <RevLink
            class="body-2 flex flex-col gap-8"
            role="menuitem"
            :to="item.url"
            :tracking="{
              zone: 'mega menu',
              name: universeTitle,
              value: item.title,
            }"
            :underlined="false"
            @click="hideMenu"
          >
            <RevIllustration
              v-if="item.image"
              :alt="item.title"
              class="rounded-lg"
              :height="960"
              :src="item.image"
              :width="1280"
            />
            <span class="text-action-default-hi body-1-bold">
              {{ item.title }}
            </span>
          </RevLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { useMegaMenu } from '../../composables/useMegaMenu'
import translations from '../TheHeader.translations'

const i18n = useI18n()
const { hideMenu } = useMegaMenu()

defineProps<{
  universeTitle: string
  universeURL: string
  items: Array<{
    id: number
    title: string
    image?: string
    url: string
  }>
}>()
</script>
