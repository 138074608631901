import type { BlockType } from '@backmarket/http-api/src/api-specs-content/models/block'

export const FULL_WIDTH_BLOCKS: BlockType[] = [
  'Carousel',
  'Faq',
  'SectionBanner',
] as const

/**
 * Full width on mobile screens
 * Fixed width on desktop screens
 *
 */
export const ADAPTIVE_WIDTH_BLOCKS: BlockType[] = ['EmailCollector'] as const

export const PADDED_FULL_WIDTH_BLOCKS: BlockType[] = ['RichText'] as const

export const NARROW_BLOCKS: BlockType[] = [
  'EmbeddedYoutubeVideo',
  'Buttons',
  'Media',
  'Categories',
] as const

function isNarrow(type: BlockType) {
  return NARROW_BLOCKS.includes(type)
}

function isFullWidthWithPadding(type: BlockType) {
  return PADDED_FULL_WIDTH_BLOCKS.includes(type)
}
export function isFullWidth(type: BlockType) {
  return FULL_WIDTH_BLOCKS.includes(type)
}

function isLarge(type: BlockType) {
  return !isNarrow(type) && !isFullWidth(type) && !isFullWidthWithPadding(type)
}

export function getBlockWidth(
  blockType: BlockType,
): 'full-width' | 'adaptive' | 'fixed' {
  if (isFullWidth(blockType)) {
    return 'full-width'
  }

  if (ADAPTIVE_WIDTH_BLOCKS.includes(blockType)) {
    return 'adaptive'
  }

  return 'fixed'
}

export function getDimensionProperties(blockType: BlockType) {
  return {
    hasPadding: !isFullWidth(blockType),
    isNarrow: isNarrow(blockType),
    isLarge: isLarge(blockType),
  }
}
