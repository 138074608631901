<template>
  <RevContainer
    class="relative mt-32 flex h-full min-h-min flex-col justify-between"
  >
    <p class="body-2 mb-16">
      {{ i18n(translations.secondStepDescription) }}
    </p>

    <CookiesSettings
      :button-label="i18n(translations.save)"
      :zone
      @error="emit('error')"
      @success="emit('success')"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'

import CookiesSettings from '../CookiesSettings/CookiesSettings.vue'

import translations from './CookiesModal.translations'

type SecondStepProps = {
  zone?: string
}

type SettingsEmit = {
  error: []
  success: []
}

withDefaults(defineProps<SecondStepProps>(), {
  zone: '',
})

const emit = defineEmits<SettingsEmit>()

const i18n = useI18n()
</script>
