export default {
  disclaimerTitle: {
    id: 'live_identity_check_consent_disclaimer_title',
    defaultMessage: 'We need consent to process your data.',
  },
  disclaimerBody: {
    id: 'live_identity_check_consent_disclaimer_body',
    defaultMessage:
      'Back Market and our identity verification partner will need to process personal and biometric data to complete your live identity check. Please read more below to see how this data will be used and how to withdraw your consent at any time.',
  },
  grantConsentCheckboxLabel: {
    id: 'live_identity_check_consent_grant_checkbox_label',
    defaultMessage:
      'I have read the above and give consent to Back Market and Onfido to process my data',
  },
  doNotProceed: {
    id: 'live_identity_check_consent_do_not_proceed',
    defaultMessage: 'Do not proceed',
  },
  proceed: {
    id: 'live_identity_check_consent_proceed',
    defaultMessage: 'Proceed with identity check',
  },
  loading: {
    id: 'live_identity_check_consent_loading',
    defaultMessage: 'Loading...',
  },
}
