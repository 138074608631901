import type { NuxtApp } from '#app'
import { defineNuxtPlugin, useNuxtApp } from '#imports'

import type { TrackingPropType } from '@backmarket/design-system/tracking/tracking.type'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import type { ModalData } from '@backmarket/nuxt-module-tracking'
import type { CarouselData } from '@backmarket/nuxt-module-tracking/types'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { emitter } from '@ds/tracking/tracking'
import type { Dateable } from '@ds/types/Dateable'

type TrackingHandler<T> = (app: NuxtApp, event: EventArgs<T>) => void

type EventArgs<T> = { action: string; context: T }

type ClickTrackingArgs = {
  name: string
  tracking?: TrackingPropType
}
const clickHandler: TrackingHandler<ClickTrackingArgs> = (
  { $tracking },
  { context },
) => {
  if (!isEmpty(context.tracking) || !isEmpty(context.name)) {
    const tracker = {
      // If a name is set (for a button for example) we can automate the tracking using it
      name: context.name,
      // If some tracking properties has been set, let's forward it to the trackClick event
      ...(context.tracking as Record<string, unknown>),
    }

    $tracking.trackClick(tracker)
  }
}

type ModalTrackingArgs = {
  name: string
  status: ModalData['status']
}

const modalHandler: TrackingHandler<ModalTrackingArgs> = (
  { $tracking },
  { context },
) => {
  $tracking.trackModal(context)
}

type ToastTrackingArgs = {
  name: string
  status: ModalData['status']
}
const toastHandler: TrackingHandler<ToastTrackingArgs> = (
  { $tracking },
  { context },
) => {
  $tracking.trackToast(context)
}

type CarouselTrackingArgs = CarouselData
const carouselHandler: TrackingHandler<CarouselTrackingArgs> = (
  { $tracking },
  { context },
) => {
  $tracking.trackCarouselImpression(context)
}

type DeprecationWarningTrackingArgs = {
  deprecated?: boolean
  deadline?: Dateable
  reason?: string
  replacementName?: string
  name: string
}
const deprecationWarningHandler: TrackingHandler<
  DeprecationWarningTrackingArgs
> = ({ $config }, { context }) => {
  const logger = useLogger()

  if ($config.public.DEV_REVOLVE_DEPRECATIONS) {
    logger.info(`[DEPRECATION_WARNING] ${context.name}`, {
      ...context,
      owners: ['bot-squad-design-system-front'],
    })
  }
}

type RemoveDeprecationWarningTrackingArgs = Pick<
  DeprecationWarningTrackingArgs,
  'deadline' | 'name' | 'reason'
>
const removeDeprecationWarningHandler: TrackingHandler<
  RemoveDeprecationWarningTrackingArgs
> = ({ $config }, { context }) => {
  const logger = useLogger()

  if ($config.public.DEV_REVOLVE_DEPRECATIONS) {
    logger.info(`[REMOVE_DEPRECATION_WARNING] ${context.name}`, {
      ...context,
      owners: ['bot-squad-design-system-front'],
    })
  }
}

/**
 * This plugin is responsible for tracking all events emitted by the design-system.
 * It is only used on the client side.
 * It start listening to design-system events while the app is mounting, and never stop listening.
 */
export default defineNuxtPlugin(() => {
  // As `defineNuxtPlugin` args returns a nuxt internal type `_NuxtApp`.
  // We use `useNuxtApp` to get the correct `NuxtApp` type...
  const nuxtApp = useNuxtApp()

  emitter.on('*', (action, event) => {
    switch (action) {
      case 'click':
        clickHandler(nuxtApp, event as EventArgs<ClickTrackingArgs>)
        break
      case 'modal':
        modalHandler(nuxtApp, event as EventArgs<ModalTrackingArgs>)
        break

      case 'toast':
        toastHandler(nuxtApp, event as EventArgs<ToastTrackingArgs>)
        break

      case 'carousel-impression':
        carouselHandler(nuxtApp, event as EventArgs<CarouselTrackingArgs>)
        break

      case 'deprecation-warning':
        deprecationWarningHandler(
          nuxtApp,
          event as EventArgs<DeprecationWarningTrackingArgs>,
        )
        break

      case 'remove-deprecated-export':
        removeDeprecationWarningHandler(
          nuxtApp,
          event as EventArgs<RemoveDeprecationWarningTrackingArgs>,
        )
        break

      default:
        break
    }
  })
})

/**
 * For test purposes only. Unsubscribe from all design-system event.
 * */
export const untrackDesignSystem = () => {
  emitter.off('*')
}
