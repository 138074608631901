<template>
  <div class="flex flex-col">
    <BlockTitle v-if="title || subtitle" :subtitle :title />

    <slot />

    <div
      class="mt-16 flex grow"
      :class="{
        'justify-end': title || subtitle,
        'justify-end md:justify-start': !title && !subtitle,
      }"
    >
      <RevButton
        v-if="hasCta"
        :icon="IconArrowRight"
        :rel="cta?.link?.rel"
        size="medium"
        :target="cta?.link?.target"
        :to="cta?.link?.href"
        variant="primary"
        @click="sendTrackingData"
      >
        {{ cta?.label }}
      </RevButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { IconArrowRight } from '@ds/icons/IconArrowRight'

import BlockTitle from './BlockTitle.vue'

const props = defineProps<{
  title?: string
  subtitle?: string
  cta?: CTALink
  tracking?: Tracking
}>()

const { trackClick } = useTracking()

const hasCta = computed(() => !isEmpty(props.cta))

function sendTrackingData() {
  if (isEmpty(props.tracking)) return
  trackClick(props.tracking)
}
</script>
