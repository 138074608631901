<template>
  <RevCardCarousel
    data-qa="card-carousel"
    :next-alternative-text="i18n(translations.alternativeNext)"
    :prev-alternative-text="i18n(translations.alternativePrevious)"
    :subtitle
    :title
  >
    <div
      v-for="(product, index) in products"
      :key="product.title"
      :ref="setupViewportTrackingProduct(product, tracking)"
      class="h-full"
    >
      <ProductCard
        data-test="product-card"
        :description="product.description"
        :discounted-price="product.discountedPrice"
        :image-src="product.image"
        :listing-id="product.listingId"
        :price="product.price"
        :price-new="product.priceNew"
        :review-rating="product.reviewRating"
        :tags
        :title="product.title"
        :url="buildProductPageLink(product)"
        v-on="addToCartListener ? { addToCart: handleAddToCart(product) } : {}"
        @click="
          trackProductClick(product, {
            ...tracking,
            position: index + 1,
            source: 'recommendation',
          })
        "
      />
    </div>

    <template v-if="cta" #button>
      <RevButton
        :icon="IconArrowRight"
        :rel="cta?.link?.rel"
        size="medium"
        :target="cta?.link?.target"
        :to="cta?.link?.href"
        variant="primary"
      >
        {{ cta?.label }}
      </RevButton>
    </template>
  </RevCardCarousel>
</template>

<script setup lang="ts">
import { getCurrentInstance } from 'vue'

import type { CTALink } from '@backmarket/http-api/src/api-specs-content/models/cta-link'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { IconArrowRight } from '@ds/icons/IconArrowRight'

import ProductCard from '@backmarket/nuxt-layer-recommendation/ProductCard.vue'

import { useProductPageLink } from '../../composables/useProductPageLink'
import {
  type ExtraTrackingData,
  useClickTracking,
  useImpressionTracking,
} from '../../composables/useProductTracking'
import type { Product } from '../../models/product'

import translations from './ProductCardCarousel.translations'

const i18n = useI18n()

const props = withDefaults(
  defineProps<{
    products: Product[]
    tracking?: ExtraTrackingData
    tags?: string[]
    useClientSideNavigation?: boolean
    title?: string
    subtitle?: string
    cta?: CTALink
  }>(),
  {
    tracking: () => ({}),
    tags: undefined,
    useClientSideNavigation: true,
    title: undefined,
    subtitle: undefined,
    cta: undefined,
  },
)

const emits = defineEmits<{
  (e: 'add-to-cart', data: { status: string; product: Product }): void
}>()

const { buildProductPageLink } = useProductPageLink({
  useClientSideNavigation: props.useClientSideNavigation,
})
const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

const { setupViewportTrackingProduct } = useImpressionTracking()
const { trackProductClick } = useClickTracking()

function handleAddToCart(product: Product) {
  return ({ status }: { status: string }) => {
    emits('add-to-cart', { status, product })
  }
}
</script>
