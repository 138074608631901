import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'

export async function getStaticHomeData(
  locale: string,
): Promise<BasePageContentAPI> {
  switch (locale) {
    case 'de-at':
      return import('./fallback.de-at').then((module) => module.default)
    case 'de-de':
      return import('./fallback.de-de').then((module) => module.default)
    case 'el-gr':
      return import('./fallback.el-gr').then((module) => module.default)
    case 'en-au':
      return import('./fallback.en-au').then((module) => module.default)
    case 'en-gb':
      return import('./fallback.en-gb').then((module) => module.default)
    case 'en-ie':
      return import('./fallback.en-ie').then((module) => module.default)
    case 'es-es':
      return import('./fallback.es-es').then((module) => module.default)
    case 'fi-fi':
      return import('./fallback.fi-fi').then((module) => module.default)
    case 'fr-be':
      return import('./fallback.fr-be').then((module) => module.default)
    case 'fr-fr':
      return import('./fallback.fr-fr').then((module) => module.default)
    case 'it-it':
      return import('./fallback.it-it').then((module) => module.default)
    case 'ja-jp':
      return import('./fallback.ja-jp').then((module) => module.default)
    case 'nl-nl':
      return import('./fallback.nl-nl').then((module) => module.default)
    case 'pt-pt':
      return import('./fallback.pt-pt').then((module) => module.default)
    case 'sk-sk':
      return import('./fallback.sk-sk').then((module) => module.default)
    case 'sv-se':
      return import('./fallback.sv-se').then((module) => module.default)

    default:
      return import('./fallback.en-us').then((module) => module.default)
  }
}
