import {
  LogLevels,
  createLogger as _createLogger,
} from '@backmarket/front-logger'

import type { Owner } from '../../types'

import { getDefaultAttributes } from './getDefaultAttributes'
import { getTransports } from './getTransports'

type CreateLoggerOptions = {
  /**
   * Additional attributes to be added to all logs. Note that these will
   * be merged with the default ones that are enabled no matter what.
   */
  attributes: Parameters<typeof _createLogger>[0]['attributes']
}

/**
 * Create a new instance logger with defaults matching our needs.
 *
 * @example
 * const logger = createLogger({
 *   attributes: {
 *     // ...
 *   },
 * })
 */
export function createLogger(options: CreateLoggerOptions) {
  const attributes = {
    ...getDefaultAttributes(),
    ...options.attributes,
  }

  return _createLogger<Owner[]>({
    level: LogLevels.INFO,
    transports: getTransports(),
    attributes,
  })
}
