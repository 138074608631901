import { useHttpFetch } from '@backmarket/nuxt-module-http-v2/useHttpFetch'

import type {
  UpdateLiveIdentityCheckConsentRequest,
  UpdateLiveIdentityCheckConsentResponse,
} from '~/api/customer-delivery/live-identity-check/consent/useUpdateLiveIdentityCheckConsent/useUpdateLiveIdentityCheckConsent.api.types'

export const ENDPOINT =
  '/customer-delivery/api/live-identity-check/:publicId/consent'

export function useUpdateLiveIdentityCheckConsent() {
  const $httpFetch = useHttpFetch()

  return (
    publicId: string,
    body: UpdateLiveIdentityCheckConsentRequest,
    signal?: AbortSignal,
  ) =>
    $httpFetch<UpdateLiveIdentityCheckConsentResponse>(ENDPOINT, {
      pathParams: { publicId },
      body,
      method: 'POST',
      signal,
    })
}
