import { removeEmptyValuesInObject } from '@backmarket/utils/object/removeEmptyValuesInObject'

import type { EventData } from '../../types'

export const reviewModel = ({
  reviewPosition,
  reviewPhotos = false,
  averageRate,
  countryCode,
  isReviewFromCurrentCountry,
  isReviewTranslated,
  photosReviewsDisplayed,
}: EventData) => {
  return removeEmptyValuesInObject({
    review_position_impression: reviewPosition,
    review_with_photos: reviewPhotos,
    review_score_impression: averageRate,
    review_from_country: isReviewFromCurrentCountry,
    review_language: countryCode,
    review_translated: isReviewTranslated,
    photos_reviews_displayed: photosReviewsDisplayed,
  })
}

export const globalRatingModel = ({ averageRate, count }: EventData) => {
  return removeEmptyValuesInObject({
    average_rate: averageRate,
    reviews_total_count: count,
  })
}
