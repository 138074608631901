<template>
  <BlockTitle :subtitle :title />
  <div
    class="flex w-full items-center justify-center gap-16 md:grid-flow-col md:gap-24"
  >
    <CmsButtonBase
      v-if="hasSecondaryCta"
      :rel="secondary?.link?.rel"
      :target="secondary?.link?.target"
      :title="secondary?.label"
      :to="secondary?.link?.href"
      variant="secondary"
      width="fixed"
      @click="() => sendTrackingData({ label: secondary?.label, position: 1 })"
    >
      {{ secondary?.label }}
    </CmsButtonBase>

    <CmsButtonBase
      :rel="primary.link?.rel"
      :target="primary.link?.target"
      :title="primary.label"
      :to="primary.link?.href"
      variant="primary"
      width="fixed"
      @click="
        () =>
          sendTrackingData({
            label: primary.label,
            position: hasSecondaryCta ? 2 : 1,
          })
      "
    >
      {{ primary.label }}
    </CmsButtonBase>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { ButtonsProps as ButtonsApiProps } from '@backmarket/http-api/src/api-specs-content/models/buttons'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'

import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'
import CmsButtonBase from '../../shared-components/CmsButtonBase/CmsButtonBase.vue'

export type ButtonsProps = ButtonsApiProps & ContentBlockProps
const props = defineProps<ButtonsProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const { trackClick } = useTracking()
const hasSecondaryCta = computed(() => !isEmpty(props.secondary))

function sendTrackingData({
  label = '',
  position,
}: {
  label?: string
  position: number
}) {
  trackClick({
    ...(props.tracking || {}),
    name: label,
    position,
  })
}
</script>
