import { useRequestURL, useRouter } from '#imports'

import { MarketCountryCode, type MarketplaceCode } from '@backmarket/http-api'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { toBcp47Locale } from '@backmarket/utils/string/toBcp47Locale'
import { type Location } from '@ds/types/Location'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'

type GetLinkReturnType = {
  baseUrl: string
  countryCode: MarketCountryCode
  locale: string
  marketplace: MarketplaceCode
  href: string
}

/**
 * Composable to calculate the absolute link for the current route in other markets
 *
 * @example
 * To calculate the link to the about-us route in the US market
 * ```
 * const { getLink } = useLinkResolver()
 * const link = getLink({
 *    link: { name: 'about-us' },
 *    countryCode: MarketCountryCode.ES,
 * })
 * ```
 *
 * @example
 * To calculate the link to the about-us route in all the markets
 * ```
 * const { getLinks } = useLinkResolver()
 * const links = getLinks({
 *    link: { name: 'about-us' }
 * })
 * ```
 */
export function useLinkResolver() {
  const { hostname } = useRequestURL()
  const { allMarkets } = useMarketplace()
  const router = useRouter()
  const formatRouteLocationWithLocale = useRouteLocationWithLocale()

  const subdomain = hostname.substring(0, hostname.indexOf('backmarket'))

  function getLink({
    link,
    countryCode,
  }: {
    link: Location
    countryCode: MarketCountryCode
  }): GetLinkReturnType | null {
    if (isEmpty(link) || !allMarkets || !allMarkets[countryCode]) {
      return null
    }

    const {
      defaultLocale,
      domain: marketDomain,
      marketplace,
    } = allMarkets[countryCode]

    const locale = toBcp47Locale(defaultLocale)
    const domain = marketDomain.substring(1)
    const baseUrl = subdomain
      ? `https://${subdomain}${domain}`
      : `https://${domain}`

    try {
      const linkWithLocale = formatRouteLocationWithLocale(link)
      const nuxtLinkWithLocale = toNuxtLink(linkWithLocale)
      const { fullPath, params } = router.resolve(nuxtLinkWithLocale)

      const path = params.locale
        ? fullPath.replace(params.locale as string, locale)
        : fullPath

      return {
        baseUrl,
        countryCode,
        locale,
        marketplace,
        href: `${baseUrl}${path}`,
      }
    } catch (error) {
      return null
    }
  }

  function getLinks({
    link,
    countryCodes = Object.values(MarketCountryCode),
  }: {
    link: Location
    countryCodes?: MarketCountryCode[]
  }): GetLinkReturnType[] {
    return countryCodes
      .map((countryCode) => getLink({ link, countryCode }))
      .filter((resolvedLink) => !!resolvedLink) as GetLinkReturnType[]
  }

  return {
    getLink,
    getLinks,
  }
}
