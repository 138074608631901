<template>
  <div>
    <div v-if="isOpen" class="fixed inset-0 isolate z-50 overflow-auto">
      <div class="bg-overlay-low fixed inset-0" data-testid="overlay" />
      <!-- We define the keydown handler on the div to catch all events -->
      <!-- emitted from child elements. We do not care about the div. -->
      <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -->
      <div
        ref="dialog"
        aria-labelledby="modalTitle"
        aria-modal="true"
        class="bg-surface-default-hi rounded-t-lg fixed bottom-0 left-0 max-h-full w-full overflow-auto md:rounded-b-lg md:bottom-auto md:left-1/2 md:top-1/2 md:w-640 md:-translate-x-1/2 md:-translate-y-1/2"
        role="dialog"
        tabindex="0"
        @keydown="focusTrap"
      >
        <section class="flex h-full max-h-screen flex-col justify-between">
          <header
            v-if="isSecondStep"
            class="relative flex items-center justify-center px-24 pb-20 pt-16 after:border-static-default-low after:absolute after:inset-x-24 after:bottom-0 after:border-b after:content-['']"
          >
            <RevButtonRounded
              :alternative-text="i18n(translations.backButton)"
              class="absolute left-14"
              :icon="IconArrowLeft"
              variant="secondary"
              @click="backToFirstStep"
            />
            <h2 id="modalTitle" class="text-static-default-hi body-1 mx-48">
              {{ title }}
            </h2>
          </header>

          <div class="h-full px-24">
            <FirstStep
              v-if="isFirstStep"
              :loading="isLoading"
              :zone
              @accept="accept"
              @params="goToSecondStep"
              @refuse="refuse"
            />

            <SecondStep
              v-if="isSecondStep"
              :zone
              @error="handleError"
              @success="close"
            />
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import { useLegalStore } from '@backmarket/nuxt-module-tracking/useLegalStore'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import { COOKIES } from '../../constants'

import translations from './CookiesModal.translations'
import FirstStep from './FirstStep.vue'
import SecondStep from './SecondStep.vue'
import { COOKIES_TRACKING_NAME, COOKIES_TRACKING_PREFIX } from './constants'

/** Composables */

const i18n = useI18n()
const { trackClick, trackModal } = useTracking()
const legalStore = useLegalStore()
const { openErrorToast } = useTheToast()

/** Reactive variables */

const step = ref(0)
const isLoading = ref(true)
const dialog = ref<HTMLElement | null>(null)

/** Computed values */

const isOpen = computed(
  () => legalStore.isModalOpen && !legalStore.shouldHideModal,
)
const isFirstStep = computed(() => step.value === 0)

const isSecondStep = computed(() => step.value === 1)

const title = computed(() =>
  isSecondStep.value ? i18n(translations.secondStepTitle) : '',
)

const name = `${COOKIES_TRACKING_PREFIX}_${COOKIES_TRACKING_NAME.MODAL}`
const zone = computed(
  () => `${COOKIES_TRACKING_NAME.MODAL_STEP}${step.value + 1}`,
)
const trackingZone = computed(() => `${COOKIES_TRACKING_PREFIX}_${zone.value}`)

/** Methods */

function close() {
  trackModal({
    zone: trackingZone.value,
    name,
    status: 'closed',
  })
  legalStore.close()
}

function handleError() {
  openErrorToast({
    title: i18n(translations.toastErrorTitle),
    content: i18n(translations.toastErrorMessage),
  })
  close()
}

async function accept() {
  try {
    isLoading.value = true

    const acceptAll = Object.keys(COOKIES).reduce(
      (acc, key) => ({ ...acc, [key]: true }),
      {},
    )

    await legalStore.save(acceptAll)

    close()
  } catch (err: unknown) {
    handleError()
  } finally {
    isLoading.value = false
  }
}

async function refuse() {
  try {
    isLoading.value = true

    const refuseAll = Object.keys(COOKIES).reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {},
    )
    await legalStore.save(refuseAll)

    close()
  } catch (err) {
    handleError()
  } finally {
    isLoading.value = false
  }
}

function backToFirstStep() {
  trackClick({ zone: trackingZone.value, name: COOKIES_TRACKING_NAME.BACK })
  step.value = 0
}

function goToSecondStep() {
  step.value = 1
}

function focusTrap(event: KeyboardEvent) {
  if (dialog.value) {
    const { key, shiftKey, target } = event

    if (key === 'Tab') {
      const focusableList = dialog.value.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      )

      if (focusableList.length < 2) {
        event.preventDefault()

        return
      }

      const last = focusableList.length - 1

      if (shiftKey === false && target === focusableList[last]) {
        ;(focusableList[0] as HTMLElement).focus()

        event.preventDefault()
      } else if (shiftKey === true && target === focusableList[0]) {
        ;(focusableList[last] as HTMLElement).focus()

        event.preventDefault()
      }
    }
  }
}

/** Lifecycle hooks */

watch(isOpen, (value) => {
  // When we close the modal we allow the user to scroll on the body again
  window.document.body.style.overflow = value ? 'hidden' : ''
  window.document.body.style.position = value ? 'relative' : ''
  window.document.body.style.height = value ? '100%' : ''
})

onMounted(() => {
  isLoading.value = false
  if (isOpen.value) {
    trackModal({
      zone: trackingZone.value,
      name,
      status: 'opened',
    })
    window.document.body.style.overflow = 'hidden'
    window.document.body.style.position = 'relative'
    window.document.body.style.height = '100%'
  }
})

onBeforeUnmount(() => {
  // When we close the modal we make sure that users can scroll on body again
  window.document.body.style.overflow = ''
  window.document.body.style.position = ''
  window.document.body.style.height = ''
})
</script>
