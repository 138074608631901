import { useAlternateLinks, useRoute } from '#imports'
import { type Ref } from 'vue'

import { MarketCountryCode } from '@backmarket/http-api'
import type { BasePageContent } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'

export function useCMSAlternateLinks(data: Ref<BasePageContent | null>) {
  if (!data.value) return []

  const dataAlternateLinks = data.value.meta?.alternateLinks || []

  const countryCodes: MarketCountryCode[] = dataAlternateLinks.map(
    ({ country }) => country as MarketCountryCode,
  )

  const { getAlternateLinks } = useAlternateLinks()
  const route = useRoute()

  return getAlternateLinks({
    link: route.path,
    countryCodes,
  })
}
