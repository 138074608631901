<template>
  <div class="mb-32 flex">
    <div>
      <slot></slot>
    </div>
    <div class="ml-16 flex-1">
      <p class="body-1-bold m-0">
        {{ title }}
      </p>

      <p
        v-for="label in labels"
        :key="label"
        class="text-static-default-low body-2 mt-8"
      >
        {{ label }}
      </p>

      <RevLink v-if="showLink" target="_blank" :to="hyperlink?.link">
        {{ hyperlink?.text }}
      </RevLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Link } from '@backmarket/http-api'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevLink } from '@ds/components/Link'

const props = defineProps<{
  title: string
  labels: Array<string>
  hyperlink?: {
    link: Link | string
    text: string
  }
}>()

const showLink = computed(() => !isEmpty(props.hyperlink))
</script>
