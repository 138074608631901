import type { Owner } from '@backmarket/nuxt-module-logger/types'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { fromEntries } from '@backmarket/utils/object/fromEntries'
import { omit } from '@backmarket/utils/object/omit'

import { type HttpContext, HttpEvent, type HttpRequestOptions } from './types'

const hideHeadersCookies = (headers: Record<string, unknown> = {}) => {
  return {
    ...headers,
    ...insertIf(Boolean(headers.cookie), { cookie: '[REDACTED]' }),
    ...insertIf(Boolean(headers['set-cookie']), { 'set-cookie': '[REDACTED]' }),
  }
}

type TrackingPayload = {
  [fieldName: string]:
    | string
    | boolean
    | number
    | Date
    | Record<string, unknown>
    | (string | boolean | number | Date | Record<string, unknown>)[]
}

/**
 * Add a callback to the `onEvent` option that build a tracking payload and pass it to the `track` callback.
 * If a logger is provided a log will be emitted on request success/failure
 * (this log is useful to create Datadog metric on specific endpoint).
 */
export const createRequestOptionsWithLogging = <T, B = Record<string, unknown>>(
  requestOptions: HttpRequestOptions<T, B>,
  log?: (
    message: string,
    attributes: TrackingPayload & {
      owners: Owner[]
    },
  ) => void,
): HttpRequestOptions<T, B> => {
  let startTime: number | undefined

  const onEventWithTracker = (event: HttpEvent, context: HttpContext<T, B>) => {
    requestOptions.onEvent?.(event, context)

    if (event === HttpEvent.Attempt) {
      startTime = performance.now()
    }

    const type = HttpEvent[event].toUpperCase()
    const message = `[${type}] ${context.endpointSettings.method} ${context.endpointSettings.path}`
    const elapsedTime = Math.floor(performance.now() - (startTime ?? 0))

    const payload = {
      type: `API_REQUEST_${type}`,
      message,
      elapsedTime,
      endpoint: {
        operationId: context.endpointSettings.operationId,
        path: context.endpointSettings.path,
      },
      request: {
        queryParams: context.requestOptions.queryParams,
        pathParams: context.requestOptions.pathParams,
      },

      // In case of success
      ...(context.response
        ? {
            status_code: context.response.status,
            responseHeaders: hideHeadersCookies(
              context.response.headers
                ? fromEntries(context.response.headers?.entries())
                : {},
            ),
          }
        : {}),

      // In case of error
      ...(context.error
        ? {
            errorMessage: context.error.message,
            requestHeaders: hideHeadersCookies(context.requestOptions.headers),
            errorType: context.error.type,
            ...omit(context.error, ['message', 'cause', 'type']),
          }
        : {}),
    }

    if (event !== HttpEvent.Attempt) {
      log?.(message, {
        ...payload,
        owners: context.endpointSettings.owners || [],
      })
    }
  }

  return { ...requestOptions, onEvent: onEventWithTracker }
}
