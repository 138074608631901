<template>
  <ProductCardCarouselSkeleton v-if="pending" :with-title="!!title" />
  <section
    v-else-if="products.length > 0"
    data-qa="recommendation-carousel"
    data-test="recommendation-carousel"
  >
    <ProductCardCarousel
      :products="productsWithDiscountedPrice"
      :subtitle
      :tags="[i18n(translations.recommendationTag)]"
      :title
      :tracking="{ ...trackingData, provider: 'attraqt' }"
      :use-client-side-navigation
      v-on="options.withAddToCart ? { addToCart: addToCartHandler } : {}"
    />
  </section>
</template>

<script lang="ts" setup>
import { watch } from 'vue'

import { useDiscountedPrice } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useDiscountedPrice'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'

import type { ExtraTrackingData } from '../composables/useProductTracking'
import { useRecommendedProducts } from '../composables/useRecommendation'

import ProductCardCarousel from './ProductCardCarousel/ProductCardCarousel.vue'
import ProductCardCarouselSkeleton from './ProductCardCarousel/ProductCardCarouselSkeleton.vue'
import translations from './Recommendation.translations'

const props = withDefaults(
  defineProps<{
    title?: string
    subtitle?: string
    recommendationQuery: {
      limit: number
      personalisation?: boolean
    } & (
      | {
          scope: string
          scopeId: string
          category: string
        }
      | {
          widgetId: string
        }
    )
    options?: {
      withCrossedPrice?: boolean
      withStartingFrom?: boolean
      withGrade?: boolean
      withAddToCart?: boolean
    }
    trackingData?: ExtraTrackingData
    useClientSideNavigation?: boolean
  }>(),
  {
    title: undefined,
    subtitle: undefined,
    options: () => ({
      withCrossedPrice: false,
      withStartingFrom: true,
      withGrade: false,
      withAddToCart: false,
    }),
    trackingData: () => ({}),
    useClientSideNavigation: true,
  },
)

const {
  products,
  pending,
  error,
  status: requestStatus,
} = useRecommendedProducts(props.recommendationQuery)
const { products: productsWithDiscountedPrice } = useDiscountedPrice(products)

const emit = defineEmits(['error', 'loaded', 'refresh'])

const i18n = useI18n()

watch(products, () => {
  // avoid emitting an error if we failed once and we are re-fetching
  if (requestStatus.value === 'pending') return

  if (products.value.length === 0) {
    emit('error', null)
  } else {
    emit('loaded', products.value)
  }
})

watch(error, () => {
  // avoid emitting an error if we failed once and we are re-fetching
  if (requestStatus.value === 'pending') return

  if (error.value !== null) {
    emit('error', error)
  }
})

const { openSuccessToast, openErrorToast } = useTheToast()

function addToCartHandler({
  status,
  product,
}: {
  status: 'success' | 'error'
  product: { listingId: string }
}) {
  if (status === 'success') {
    openSuccessToast({
      title: i18n(translations.toastSuccessTitle),
      content: i18n(translations.toastSuccessMessage),
    })
    emit('refresh', product.listingId)
  }
  if (status === 'error') {
    openErrorToast({
      title: i18n(translations.toastErrorTitle),
      content: i18n(translations.toastErrorMessage),
    })
  }
}
</script>
