<template>
  <EnterViewportDetector @enter="inViewport = true">
    <div
      class="mx-auto mb-56 flex"
      :class="hasManyEmbeds ? 'md:w[1120px]' : 'md:w-[736px]'"
    >
      <div
        class="w-full justify-items-center"
        :class="
          singleEmbed
            ? embedSizes[socialMediaType][size]?.wrapperSingle
            : embedSizes[socialMediaType][size]?.wrapperCarousel
        "
      >
        <RevSkeleton
          v-if="showSkeleton"
          :class="singleEmbed ? 'w-[325px]' : 'w-full'"
          data-test="social-embed-skeleton"
          shape="rectangle"
        />

        <div
          v-if="inViewport"
          class="h-full w-full content-center"
          :class="showSkeleton ? 'hidden' : 'block'"
          data-test="social-embed-wrapper"
        >
          <TitleCtaContainer v-if="singleEmbed" class="w-full" :subtitle :title>
            <iframe
              class="rounded-lg mx-auto min-w-[325px] max-w-[605px]"
              :class="embedSizes[socialMediaType][size].single"
              data-test="social-embed-iframe"
              :src="source(identifiers[0])"
              :title="technicalName"
              @load="itemsLoaded += 1"
            />
          </TitleCtaContainer>

          <RevCardCarousel
            v-else
            class="w-full md:w-[1120px]"
            :next-alternative-text="i18n(translations.alternativeNext)"
            :prev-alternative-text="i18n(translations.alternativePrevious)"
            size="medium"
            :subtitle
            :title
          >
            <iframe
              v-for="identifier in identifiers"
              :key="identifier"
              class="rounded-lg w-[260px] max-w-[605px] md:min-w-[325px]"
              :class="embedSizes[socialMediaType][size].carousel"
              :data-test="`social-embed-iframe-${identifier}`"
              :src="source(identifier)"
              :title="technicalName"
              @load="itemsLoaded += 1"
            />
          </RevCardCarousel>
        </div>
      </div>
    </div>
  </EnterViewportDetector>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { SocialMediaEmbedProps } from '@backmarket/http-api/src/api-specs-content/models/social-media-embed'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { tw } from '@backmarket/utils/string/tw'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevSkeleton } from '@ds/components/Skeleton'

import EnterViewportDetector from '../../shared-components/EnterViewportDetector/EnterViewportDetector.vue'
import TitleCtaContainer from '../../shared-components/TitleCtaContainer/TitleCtaContainer.vue'

import translations from './SocialMediaEmbed.translations'

const props = defineProps<SocialMediaEmbedProps>()

const i18n = useI18n()
const logger = useLogger()

const inViewport = ref<boolean>(false)
const itemsLoaded = ref<number>(0)

type EmbedSize = Record<
  SocialMediaEmbedProps['socialMediaType'],
  Record<
    SocialMediaEmbedProps['size'],
    {
      single: string
      wrapperSingle: string
      carousel: string
      wrapperCarousel: string
    }
  >
>

const embedSizes: EmbedSize = {
  InstagramPost: {
    Small: {
      single: tw`h-[560px]`,
      wrapperSingle: tw`h-[600px]`,
      carousel: tw`h-[480px]`,
      wrapperCarousel: tw`h-[520px]`,
    },
    Medium: {
      single: tw`h-[560px]`,
      wrapperSingle: tw`h-[600px] md:h-[580px]`,
      carousel: tw`h-[480px] md:h-[560px]`,
      wrapperCarousel: tw`h-[560px] md:h-[640px]`,
    },
    Large: {
      single: tw`h-[660px]`,
      wrapperSingle: tw`h-[700px]`,
      carousel: tw`h-[580px]`,
      wrapperCarousel: tw`h-[620px]`,
    },
  },
  InstagramReel: {
    Small: {
      single: tw`h-[620px]`,
      wrapperSingle: tw`h-[630px]`,
      carousel: tw`h-[530px]`,
      wrapperCarousel: tw`h-[580px]`,
    },
    Medium: {
      single: tw`h-[620px]`,
      wrapperSingle: tw`h-[660px] md:h-[670px]`,
      carousel: tw`h-[535px] md:h-[620px]`,
      wrapperCarousel: tw`h-[590px] md:h-[670px]`,
    },
    Large: {
      single: tw`h-[720px]`,
      wrapperSingle: tw`h-[730px]`,
      carousel: tw`h-[630px]`,
      wrapperCarousel: tw`h-[680px]`,
    },
  },
  TikTokPost: {
    Small: {
      single: tw`h-[420px]`,
      wrapperSingle: tw`h-[430px]`,
      carousel: tw`h-[380px]`,
      wrapperCarousel: tw`h-[460px]`,
    },
    Medium: {
      single: tw`h-[520px]`,
      wrapperSingle: tw`h-[565px]`,
      carousel: tw`h-[430px]`,
      wrapperCarousel: tw`h-[535px] md:h-[500px]`,
    },
    Large: {
      single: tw`h-[620px]`,
      wrapperSingle: tw`h-[630px]`,
      carousel: tw`h-[520px] md:h-[540px]`,
      wrapperCarousel: tw`h-[600px]`,
    },
  },
  XPost: {
    Small: {
      single: tw`h-[300px]`,
      wrapperSingle: tw`h-[320px]`,
      carousel: tw`h-[370px]`,
      wrapperCarousel: tw`h-[390px]`,
    },
    Medium: {
      single: tw`h-[370px] md:h-[360px]`,
      wrapperSingle: tw`h-[390px] md:h-[380px]`,
      carousel: tw`h-[420px]`,
      wrapperCarousel: tw`h-[440px]`,
    },
    Large: {
      single: tw`h-[630px] md:h-[650px]`,
      wrapperSingle: tw`h-[650px] md:h-[670px]`,
      carousel: tw`h-[600px] md:h-[650px]`,
      wrapperCarousel: tw`h-[640px] md:h-[670px]`,
    },
  },
}

function source(contentId: string) {
  switch (props.socialMediaType) {
    case 'InstagramPost':
      return `https://www.instagram.com/p/${contentId}/embed/`
    case 'InstagramReel':
      return `https://www.instagram.com/reel/${contentId}/embed`
    case 'TikTokPost':
      return `https://www.tiktok.com/embed/v3/${contentId}`
    case 'XPost':
      return `https://platform.twitter.com/embed/Tweet.html?id=${contentId}`
    default:
      logger.error(
        `The social media type: "${props.socialMediaType}" is not supported by this block.`,
        { owners: ['bot-squad-content-front'] },
      )

      return ''
  }
}

const showSkeleton = computed(() => {
  return itemsLoaded.value !== props.identifiers.length
})

const hasManyEmbeds = computed(() => {
  return props.identifiers.length > 2
})

const singleEmbed = computed(() => {
  return props.identifiers.length === 1
})
</script>
