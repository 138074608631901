<template>
  <!-- eslint-disable vue/no-v-html -->
  <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
  <div v-html="html" />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type RichText } from '@backmarket/http-api/src/api-specs-content/models/rich-text'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

import { parseRichText } from '../../blocks/RichText/DynamicTextDates.utils'

const props = defineProps<{ richText?: RichText }>()

const locale = useI18nLocale()

const html = computed(() => {
  if (isEmpty(props?.richText)) return ''

  const parsedRichText = parseRichText(props.richText, locale)

  return documentToHtmlString(parsedRichText)
})
</script>
