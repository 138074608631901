<template>
  <Transition enter-from-class="opacity-0" leave-to-class="opacity-0">
    <RevBackdrop
      v-if="isMenuActive"
      class="z-10 hidden backdrop-blur-2xl transition-opacity duration-200 md:block"
    />
  </Transition>
  <header
    id="header"
    class="bg-surface-default-low border-static-default-low sticky top-0 z-10 border-b transition-all duration-200"
    :class="{ '-translate-y-full': headerIsHidden && !isOpen }"
  >
    <ClientOnly>
      <CountrySwitchRedirection
        v-if="hasCountrySwitchRedirection"
        ref="countrySwitchBannerRef"
      />
    </ClientOnly>
    <HeaderBar
      :class="{ 'hidden md:flex': isSmallScreenHeaderExperimentEnabled }"
      data-qa="header-bar"
    />
    <HeaderBarSmallScreen
      v-if="isSmallScreenHeaderExperimentEnabled"
      class="md:hidden"
      data-qa="header-bar"
    />
    <NavMenu class="hidden md:block" />
    <NavBurgerMenu v-if="isOpen" class="md:hidden" />
  </header>
</template>

<script setup lang="ts">
import { useAsyncData } from '#imports'
import { computed, ref } from 'vue'

import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { RevBackdrop } from '@ds/components/Backdrop'

import { useHideOnScroll } from '../composables/useHideOnScroll'
import { useNavigation } from '../composables/useNavigation'

import CountrySwitchRedirection from './CountrySwitch/CountrySwitchRedirection.vue'
import HeaderBar from './HeaderBar.vue'
import HeaderBarSmallScreen from './HeaderBarSmallScreen.vue'
import NavBurgerMenu from './NavBurgerMenu/NavBurgerMenu.vue'
import NavMenu from './NavMenu/NavMenu.vue'

const { isMenuActive, fetchMenuItems, isOpen } = useNavigation()
useAsyncData(fetchMenuItems)

const { hidden: headerIsHidden } = useHideOnScroll()
const experiments = useExperiments()

const isSmallScreenHeaderExperimentEnabled = computed(() => {
  return experiments['experiment.reduceHeader'] === 'small'
})

const countrySwitchBannerRef = ref<HTMLElement | null>()

const hasCountrySwitchRedirection = computed(() => {
  return (
    experiments['experiment.countrySwitchRedirection'] === 'withRedirection'
  )
})
</script>
