<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
  <RevLink
    class="body-2 text-nowrap transition-all duration-700 ease-in-out hover:body-2-bold hover:no-underline"
    :class="{
      'body-2-bold': isActivePage(url) && !isMenuActive,
      'text-action-default-low': isMenuActive,
    }"
    :style="{ color: color }"
    :to="url"
    :tracking="{
      zone: 'header',
      name: 'department',
      value: title,
    }"
    :underlined="false"
  >
    <div
      class="flex h-full items-center gap-4 border-t-transparent"
      :class="[
        isActivePage(url) && !isMenuActive
          ? 'border-b-static-default-hi border-y-2'
          : '',
      ]"
    >
      <component :is="icon" />
      <span class="bold-hack" :title>{{ title }}</span>
    </div>
  </RevLink>
</template>

<script setup lang="ts">
import type { Component } from 'vue'

import { RevLink } from '@ds/components/Link'

import { useMegaMenu } from '../../composables/useMegaMenu'

const { isMenuActive, isActivePage } = useMegaMenu()

defineProps<{
  title: string
  icon: Component
  url: string
  color: string
}>()
</script>

<style scoped>
.bold-hack::after {
  display: block;
  content: attr(title);
  font-weight: 600;
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  contain: content;
  border: 1px solid black;
}
</style>
