<template>
  <div class="h-56 py-8">
    <div
      class="flex flex-nowrap motion-safe:duration-200 motion-safe:ease-in-out"
      :class="{
        'motion-safe:-translate-x-full': isSearchOpened,
      }"
    >
      <div
        class="flex w-full shrink-0 flex-nowrap items-center gap-8 pl-24 pr-12"
      >
        <TheLogo class="flex grow" />

        <div class="flex items-center justify-end">
          <div class="flex size-40 items-center justify-center">
            <button :aria-label="i18n(translations.search)" @click="openSearch">
              <IconSearch size="24" />
            </button>
          </div>
          <div class="flex size-40 items-center justify-center">
            <UserIcon />
          </div>
          <div class="flex size-40 items-center justify-center">
            <CartIconAndBubble data-test="icon-cart" />
          </div>
          <div class="flex size-40 items-center justify-center lg:hidden">
            <MenuIcon />
          </div>
        </div>
      </div>

      <div
        class="w-full shrink-0 items-center gap-8 px-12"
        :class="{
          flex: isSearchOpened,
          hidden: !isSearchOpened,
        }"
      >
        <RevButtonRounded
          :alternative-text="i18n(translations.back)"
          :icon="IconArrowLeft"
          variant="secondary"
          @click="closeSearch"
        />
        <SearchBar
          class="grow"
          fullscreen
          :should-focus-input="shouldFocusSearch"
          @submit="closeSearch"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButtonRounded } from '@ds/components/ButtonRounded'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'
import { IconSearch } from '@ds/icons/IconSearch'

import SearchBar from '~/scopes/search/SearchBar/SearchBar.vue'

import CartIconAndBubble from './CartIcon.vue'
import MenuIcon from './MenuIcon.vue'
import translations from './TheHeader.translations'
import TheLogo from './TheLogo.vue'
import UserIcon from './UserIcon.vue'

const animationDuration = 200
const isSearchOpened = ref(false)
const shouldFocusSearch = ref(false)
const i18n = useI18n()

function awaitEndOfAnimation() {
  setTimeout(() => {
    shouldFocusSearch.value = true
  }, animationDuration)
}

function openSearch() {
  isSearchOpened.value = true
  awaitEndOfAnimation()
  window.document.body.style.overflow = 'hidden'
  window.document.body.style.height = '100%'
}
function closeSearch() {
  isSearchOpened.value = false
  shouldFocusSearch.value = false
  window.document.body.style.overflow = ''
  window.document.body.style.height = ''
}
</script>
