<template>
  <div class="flex w-full items-center gap-32">
    <slot />

    <div
      class="absolute flex h-full w-full flex-col items-start justify-center gap-24 px-48"
    >
      <div class="flex w-full flex-col px-12 md:px-48" :class="alignment">
        <UnstyledRichText
          v-if="title"
          class="mb-12 select-all"
          :class="titleClass"
          :default-style="false"
          :rich-text="title"
        />

        <UnstyledRichText
          v-if="subtitle"
          class="text-heading-3 mb-24 select-all md:text-heading-2"
          :default-style="false"
          :rich-text="subtitle"
        />

        <div
          v-if="link?.href && linkLabel"
          :class="['flex', CTAColours.wrapper ?? '']"
        >
          <RevButton
            class="!bg-local-cms-action-brand hover:!bg-local-cms-action-brand-hover"
            :class="CTAColours.button ?? ''"
            size="medium"
            variant="primary"
            @click="onCTAClick"
          >
            <span :class="CTAColours.text">
              <span class="text-local-cms-brand select-text">
                {{ linkLabel }}
              </span>
            </span>
          </RevButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed } from 'vue'

import type {
  CarouselSlide,
  Mood,
  Tone,
} from '@backmarket/http-api/src/api-specs-content/models/carousel-content'
import { tw } from '@backmarket/utils/string/tw'
import { RevButton } from '@ds/components/Button'

import UnstyledRichText from '../../shared-components/UnstyledRichText/UnstyledRichText.vue'

type Selectors = { button?: string; text: string; wrapper?: string }

const props = withDefaults(defineProps<CarouselSlide>(), {
  alignment: 'Left',
  fontType: 'Ivar',
  titleFontSize: 'L',
  ctaStyling: 'mid-tones_tangaroa',
  linkLabel: '',
})

defineOptions({ inheritAttrs: false })

const router = useRouter()

const alignment = computed(() => {
  return props.alignment === 'Left'
    ? tw`items-start text-left`
    : tw`items-center text-center`
})

const combinations: Record<Tone, Record<Mood, Selectors>> = {
  mindaro: {
    bush: { text: tw`mood-bush`, wrapper: tw`mood-main` },
    coffee: { text: tw`mood-coffee`, wrapper: tw`mood-main` },
    tangaroa: { text: tw`mood-tangaroa`, wrapper: tw`mood-main` },
    violet: { text: tw`mood-violet`, wrapper: tw`mood-main` },
  },
  'dark-tones': {
    bush: { button: tw`mood-bush`, text: tw`mood-main` },
    coffee: { button: tw`mood-coffee`, text: tw`mood-main` },
    tangaroa: { button: tw`mood-tangaroa`, text: tw`mood-main` },
    violet: { button: tw`mood-violet`, text: tw`mood-main` },
  },
  'mid-tones': {
    bush: { button: tw`mood-emerald`, text: tw`mood-bush` },
    coffee: { button: tw`mood-blush`, text: tw`mood-coffee` },
    tangaroa: { button: tw`mood-cornflower`, text: tw`mood-tangaroa` },
    violet: { button: tw`mood-purple`, text: tw`mood-violet` },
  },
}

const CTAColours = computed(() => {
  if (!props.link || !props.ctaStyling) return { text: '' }

  const styleSplit = props.ctaStyling.split('_')
  const tone = styleSplit[0] as Tone
  const mood = styleSplit[1] as Mood

  return combinations[tone][mood]
})

const titleClass = computed(() => {
  let fontType = ''
  if (props.fontType) {
    fontType +=
      props.fontType === 'Ivar' ? tw`font-punchline` : tw`font-heading-2`
  }

  switch (props.titleFontSize) {
    case 'S':
      return tw`leading-[2.25rem] md:leading-[3rem] text-[2.25rem]  md:text-[3rem] font-weight-heading-2 -tracking-[0.09rem] md:-tracking-[0.12rem] ${fontType}`
    case 'M':
      return tw`leading-[2.625rem] md:leading-[3.5rem] text-[2.5rem] md:text-[3.5rem] font-weight-heading-2 -tracking-[0.105rem] md:-tracking-[0.14rem] ${fontType}`
    case 'L':
      return tw`leading-[2.75rem] md:leading-[4rem] text-[2.75rem] md:text-[4rem] font-weight-heading-2 -tracking-[0.16rem] md:-tracking-[0.11rem] ${fontType}`
    default:
      return fontType
  }
})

function onCTAClick(event: Event) {
  if (!props.link) return

  event.preventDefault()

  const resolved = router.resolve(props.link.href)
  if (resolved.matched.length) {
    router.push(resolved.fullPath)

    return
  }

  window.open(props.link.href, props.link.target)
}
</script>
