import type { DefaultHttpRequestBody, HttpError } from '@backmarket/http-api'
import type {
  BasePageContent,
  BasePageContentAPI,
  MappedApiPageContent,
} from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { useFormatContentResponse } from './useFormatContentResponse'

/**
 * @returns T with all keys of O overridden by the type of O
 */
type Override<T, O extends object> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends Record<any, any> ? Omit<T, keyof O> & O : O

export function useFetchFormattedPageContent<
  T extends BasePageContentAPI,
  ErrorT = HttpError,
  BodyT = DefaultHttpRequestBody,
  DataT extends BasePageContent = BasePageContent,
>(
  endpoint: Parameters<typeof useHttpFetch<T, ErrorT, BodyT, DataT>>[0],
  options: Override<
    Parameters<typeof useHttpFetch<T, ErrorT, BodyT, DataT>>[1],
    {
      transform?: (data: MappedApiPageContent<T>) => DataT
    }
  >,
) {
  const formatContentResponse = useFormatContentResponse()

  const { transform, ...restOptions } = options || {}

  const transformFn: (data: T) => DataT = transform
    ? (data: T) => transform(formatContentResponse(data))
    : (formatContentResponse as (data: T) => DataT)

  return useHttpFetch(endpoint, {
    ...restOptions,
    transform: transformFn,
  })
}
