<template>
  <RevAccordionItem
    :collapsed="state"
    :render-body="true"
    :wrapper-attrs="{ class: 'border-none' }"
    @click="updateState"
  >
    <template #title>
      <h3 class="text-action-default-hi heading-3 mr-24">{{ title }}</h3>
    </template>

    <template #body>
      <div v-if="image" class="flex flex-col gap-12 md:flex-row md:gap-20">
        <div class="rounded-md md:w-[256px]">
          <RevIllustration v-bind="image" />
        </div>

        <div class="md:max-w-[55%]">
          <RichText :light-text="true" :rich-text="text" />
        </div>
      </div>

      <RichText v-else :light-text="true" :rich-text="text" />
    </template>
  </RevAccordionItem>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { ref } from 'vue'

import type { FaqQuestion } from '@backmarket/http-api/src/api-specs-content/models/faq'
import type { Tracking } from '@backmarket/http-api/src/api-specs-content/models/tracking'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevAccordionItem } from '@ds/components/AccordionItem'
import { RevIllustration } from '@ds/components/Illustration'

import RichText from '../RichText/RichText.vue'

type EventState = { triggered: boolean; initialState: FaqQuestion['state'] }

const props = withDefaults(
  defineProps<FaqQuestion & { tracking: Tracking; position: number }>(),
  {
    state: 'collapsed',
  },
)

const { trackClick } = useTracking()
const route = useRoute()

const state = ref<FaqQuestion['state']>(props.state)
const eventState = ref<EventState>({
  triggered: false,
  initialState: props.state,
})

function updateState() {
  state.value = state.value === 'collapsed' ? 'expanded' : 'collapsed'

  if (
    state.value === 'expanded' &&
    eventState.value.initialState !== 'expanded' &&
    !eventState.value.triggered
  ) {
    const routeName = route.name?.toString() ?? ''

    const zone = props.tracking.zone ?? routeName
    const blockPosition = props.tracking.blockPosition ?? 1

    trackClick({
      block_position: blockPosition,
      position: props.position,
      name: props.title,
      value: 'FAQ',
      zone,
    })
    eventState.value.triggered = true
  }
}
</script>
