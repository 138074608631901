export default {
  alternativeButtonLabel: {
    id: 'alternative-button-label',
    defaultMessage: 'Slide',
  },
  alternativeNext: {
    id: 'alternative-next',
    defaultMessage: 'Next',
  },
  alternativePrevious: {
    id: 'alternative-previous',
    defaultMessage: 'Previous',
  },
}
