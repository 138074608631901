<template>
  <RevList :has-external-borders="false">
    <PromoCodePerk
      :body="i18n(translations.unlimitedTalkAndTextDescription)"
      :title="i18n(translations.unlimitedTalkAndTextTitle)"
    />
    <PromoCodePerk
      :body="i18n(translations.populationCoverage)"
      :title="i18n(translations.premiumCoverageTitle)"
    />
    <PromoCodePerk
      :body="i18n(translations.spamAndRobocallsBlockedContent)"
      :title="i18n(translations.spamAndRobocallsBlockedTitle)"
    />
  </RevList>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevList } from '@ds/components/List'

import PromoCodePerk from '../../../PromoCodePerk/PromoCodePerk.vue'

import translations from './VerizonNetworkList.translations'

const i18n = useI18n()
</script>
